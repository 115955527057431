import ExtraTooltip from "components/general/ExtraTooltip";
import "./offer-title-place.scss";

export default function OfferTitlePlace({ title, TitleTag = "h3", places }) {
	
	const tooltip_items = places && places.length > 2 ? places.map(({ city, country }) => (
		city && country
			? city + ", " + country
			: city || country
	)) : null

	return (
		<div className="offer-title-place">
			<TitleTag> { title } </TitleTag>
			
			{ places && !!places.length &&
				places.map(({ city, country }, i ) => i < 2 ? (
					<div key={ i }> 
						{( city && country ) 
							? <span> { city }, { country } </span> 
							: <span> { city || country || "" } </span>
						}
					</div>
				) : null )
			}

			<ExtraTooltip 
				heading="all_offer_places" 
				items={ tooltip_items }
			/>
		</div>
	)
}