import { languages, useLocale } from "context/LanguageContext";

import Widget from "../Widget";
import "./language-switcher.scss";

export default function LanguageSwitcher() {
	
	const { locale, changeLocale } = useLocale();
	const current_svg = languages.find( i => i.locale === locale )?.svg;

	return (
		<Widget 
			className="language-switcher"
			svg={ current_svg }
		>
			{ languages.filter( i => i.locale !== locale ).map(({ locale, svg }) => (
				<a
					href={`#${ locale }`} 
					key={ locale } 
					onClick={ e => {
						e.preventDefault();
						changeLocale( locale );
					}}
				>
					{ svg }
				</a>
			))}
		</Widget>
	)
}