import API from "api/API";
import useFetchData from "./useFetchData";

const defaultResponseDataHandler = data => data.map( t => ({ 
	value: String( t.term_id ), 
	label: t.name,
	icon_url: t.acf?.ikona?.url 
}));


export default function( taxonomy, responseDataHandler = defaultResponseDataHandler ) {
	return useFetchData({
		APIMethod: ( ...arg ) => API.get( `/terms/${ taxonomy }`, { cancelToken: arg[1] }),
		data_param_name: "",
		cache_name: taxonomy,
		only_data: true,
		responseDataHandler: responseDataHandler
	})
}