import { useTranslate } from "context/LanguageContext";
import { useMemo } from "react";

export default function useOfferPrice({ adults, kids, sale, price = {} }) {

	const translate = useTranslate();

	const people_amount = useMemo(() => (
		( +kids || 0 ) + ( +adults || 0 )
	), [ kids, adults ])


	const people_amount_label = useMemo(() => (
		people_amount === 1
			? translate( "for_1_person" )
			: `${ translate( "for" )} ${ people_amount } ${ translate( people_amount <= 4 ? "persons_2" : "persons" )}` 
	), [ people_amount, translate ])


	const for_one_person = useMemo(() => (
		sale ? ( price.adult_sale || price.adult ) : price.adult
	), [ price, sale ])


	const total_price = useMemo(() => (
		( +kids || 0 ) * price.child + ( +adults || 0 ) * price.adult
	), [ kids, adults, price ])


	const sale_price = useMemo(() => sale ? (
		( +kids || 0 ) * price.child_sale + ( +adults || 0 ) * price.adult_sale
	) : 0, [ sale, kids, adults, price ])


	return { 
		for_one_person: ( for_one_person / 100 ) || 0, 
		total_price: ( total_price / 100 ) || 0, 
		sale_price: ( sale_price / 100 ) || 0, 
		people_amount: people_amount || 0, 
		people_amount_label
	};
}