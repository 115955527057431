import { createContext, useEffect, useState } from "react";
import UserAPI from "api/UserAPI";
import { useUser } from "./AuthContext";
import responseItemHandler from "propsFormat/Offer";
import { useContext } from "react";

const FavoritesContext = createContext();

export default function FavoritesContextProvider({ children }) {

	const user = useUser();
	const [ favorites, setFavorites ] = useState( null );

	const getAllFavorites = () => {

		setFavorites( null );

		UserAPI.allFavorites()
		.then( res => {

			const posts = res.data.posts || [];
			setFavorites( posts.map( p => responseItemHandler( p )))
		})
	}


	const defineIsFavorite = offer => !!favorites?.find( i => i.id === offer.id );


	const toggleFavorite = offer => {
		
		const is_favorite = defineIsFavorite( offer );

		UserAPI.addFavorite( offer.id )
		.then(() => {

			const updated_favorites = is_favorite 
				? favorites?.filter( i => i.id !== offer.id ) 
				: [...favorites, offer ];

			setFavorites( updated_favorites );
		})
	}


	useEffect(() => {
		user 
			? getAllFavorites() 
			: setFavorites([]);
	}, [ user?.id ])


	return (
		<FavoritesContext.Provider value={{ favorites, defineIsFavorite, toggleFavorite }}>
			{ children }
		</FavoritesContext.Provider>
	)
}


export const useFavoritesHandlers = () => {
	const { defineIsFavorite, toggleFavorite } = useContext( FavoritesContext );
	return { defineIsFavorite, toggleFavorite };
};


export const useFavorites = () => useContext( FavoritesContext ).favorites;