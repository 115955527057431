import axios from "axios";
import Storage from "extra/Storage";
import API from "./API";

const API_ = axios.create({ baseURL: window.config.ORIGIN + "/api/wp-json/wp/v2" })

export default {

	leaveReview: data => API_.post( "/comments", {
		parent: 0,
		post: data.offer_id,
		content: data.content,
		"meta[rating]": data.rating
	}, {
		headers: { 
			Authorization: `Bearer ${ Storage.get( "token" )}`,
			'Content-Type': "multipart/form-data"
		}
	}),

	addFavorite: offer_id => API.post( "/users/favorites", { offer_id }),

	allFavorites: () => API.get( "/users/favorites" ),

	saveSearchParams: data => API.post( "/users/save-search", data ),

	getSavedSearches: () => API.get( "/users/save-search" ),

	deleteSavedSearch: id => API.delete( `/users/save-search/${ id }` ),

	updateUserInfo: data => API.post( "/users", {
		country: data.country,
		url: data.url,
		profile_image: data.profile_image,
		task: "profile"
	}, { headers: { 'Content-Type': "multipart/form-data" }}),

	changePassword: data => API.post( "/users", {
		password: data.password,
		new_password: data.new_password,
		task: "password"
	}),

	updateNotificationSettings: data => API.post( "/users", {
		notification_favorite: data.notification_favorite ? 1 : 0,
		notification_search_parameters : data.notification_search_parameters ? 1 : 0,
		notification_email: data.notification_email ? 1 : 0,
		task: "settings"
	}),

	getNotifications: () => API.get( "/users/notifications" )
}