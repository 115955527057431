import React from 'react';
import { isFunction } from 'extra/functions';

export default function useWindowClick( fn ) {
	
	React.useEffect(() => {

		if ( !isFunction( fn )) return;

		window.addEventListener( "click", fn );
		return () => window.removeEventListener( "click", fn );
	}, []);
}