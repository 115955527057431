import { CheckedSvg } from "svg";
import "./checkbox.scss";

export default function Checkbox( props ) {
	
	const { name, extra_classes = "", checked, children, onClick } = props;
	const className = [ "checkbox", checked ? "checked" : "", extra_classes ].join(" ");

	return (
		<div 
			className={ className } 
			onClick={() => onClick && onClick( name, !checked )}
		>

			<div className="checkbox-box"> 
				{ !!checked && <CheckedSvg/> }
			</div>

			{ children && 
				<div className="checkbox-label">
					{ children }
				</div>
			}
		</div>
	)
}