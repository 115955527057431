import useOfferAttributes from "hooks/Offer/useOfferAttributes";
import Dropdown from "../Dropdown";

export default function DepartureAttrDropdown( props ) {

	const departure_options = useOfferAttributes();

	return (
		<Dropdown
			label="attributes"
			search_placeholder="departure_attributes"
			options={ departure_options }
			{...props }
		/>
	)
}