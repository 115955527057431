import Menu from "../Menu"; 
import Paths from "routes/Paths";

import { useLogOut } from "context/AuthContext";
import { LogoutSvg } from "svg";
import { SearchFavoriteSvg, SettingsSvg, AddSquareSvg, HeartStrokeSvg, NotificationStrokeSvg, Settings2Svg } from "svg/profile";

import "./profile-menu.scss";

export default function ProfileMenu({ widget }) {
	
	const logOut = useLogOut();

	const links = [
		{ label: "saved_searches", svg: <SearchFavoriteSvg/>, path: Paths.Profile.SavedSearch },
		{ label: "profile_settings", svg: <SettingsSvg/>, path: Paths.Profile.Settings },
		{ label: "create_own_trip", svg: <AddSquareSvg/>, path: Paths.Profile.CreateOwnTrip },
		{ label: "favorite_trips", svg: <HeartStrokeSvg/>, path: Paths.Profile.Favorites, hide: widget },
		{ label: "notifications", svg: <NotificationStrokeSvg/>, path: Paths.Profile.Notifications, hide: widget },
		{ label: "notification_settings", svg: <Settings2Svg/>, path: Paths.Profile.NotificationSettings, hide: widget },
		{ 	
			label: "log_out",
			svg: <LogoutSvg/>, 
			href: "#logout", 
			hide: !widget,
			onClick: e => {
				e.preventDefault();
				logOut();
			}
		}
	]
	
	return (
		<Menu className="profile-menu" links={ links }/>
	)
}