import { useUser } from "context/AuthContext";
import { useFavoritesHandlers } from "context/FavoritesContext";

import "./favorite-button.scss";

export default function FavoriteButton({ offer }) {

	const user = useUser();
	const { defineIsFavorite, toggleFavorite } = useFavoritesHandlers();
	const is_favorite = defineIsFavorite( offer );
	const svg_props = is_favorite ? { fill: "#FFD600" } : { stroke: "#FFD600", strokeWidth: "1.5" };

	return user ? (
		<button type="button" className="favorite-button" onClick={ e => {
			e.preventDefault();
			e.stopPropagation();

			toggleFavorite( offer );
		}}>

			<svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M8.39955 2.39844L9.00008 3.20113L9.60062 2.39844C10.3074 1.45375 11.4391 0.84082 12.7001 0.84082C14.8407 0.84082 16.5834 2.58486 16.5834 4.74915C16.5834 5.65925 16.4384 6.49811 16.1865 7.27662L16.1865 7.27662L16.1849 7.2815C15.5803 9.19489 14.337 10.7486 12.9789 11.9158C11.6176 13.0858 10.184 13.8299 9.27516 14.1391L9.27515 14.1391L9.26713 14.1419C9.2307 14.1548 9.13396 14.1742 9.00008 14.1742C8.8662 14.1742 8.76946 14.1548 8.73303 14.1419L8.73305 14.1419L8.725 14.1391C7.81614 13.8299 6.38258 13.0858 5.02122 11.9158C3.66315 10.7486 2.41986 9.19489 1.81523 7.2815L1.81524 7.2815L1.81366 7.27662C1.56179 6.49811 1.41675 5.65925 1.41675 4.74915C1.41675 2.58486 3.15946 0.84082 5.30008 0.84082C6.56106 0.84082 7.69278 1.45375 8.39955 2.39844Z" {...svg_props }/>
			</svg>

		</button>
	) : null
}