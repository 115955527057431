import useGoToSearch from "hooks/useGoToSearch";
import { useTranslate } from "context/LanguageContext";

import TransportCheckboxes from "components/form/TransportCheckboxes";
import CategoriesDropdown from "components/form/CategoriesDropdown";
import DepartureAttrDropdown from "components/form/DepartureAttrDropdown";
import DateRange from "components/form/DateRange";
import WhereDropdown from "components/form/WhereDropdown";
import PeopleCounter from "components/form/PeopleCounter";
import Button from "components/buttons/Button";

import "./style/search-panel.scss";
import "./style/search-panel-responsive.scss";

export default function SearchPanel({ params, to_search_page, onParamChange, ...props }) {

	const translate = useTranslate();
	const goToSearch = useGoToSearch();

	const onSubmit = to_search_page ? p => goToSearch( p ) : props.onSubmit;

	
	return params ? (
		<div className="search-panel">

			<div className="search-panel-top">
				<TransportCheckboxes
				 	default_value={ params.transport }
				 	onChange={ onParamChange }
				/>
			</div>

			<div className="search-panel-main"> 

				<CategoriesDropdown 
					name="categories"
					default_value={ params.categories }
					onChange={ onParamChange }
				/>

				<DepartureAttrDropdown 
					name="departure_attr"
					default_value={ params.departure_attr }
					onChange={ onParamChange }
				/>
				
				<DateRange
					default_value={{
						from: params.date_from,
						to: params.date_to,
						tour_length: params.tour_length,
						open_date: params.open_date
					}}
					onChange={ values => onParamChange( values )}
				/>

				<WhereDropdown
					offer_count
					name="where" 
					default_value={ params.where }
					default_continent={ params.continent }
					onChange={ onParamChange }
				/>
				
				<PeopleCounter
					default_value={{
						kids: +params.kids || 0,
						adults: +params.adults || 0
					}}
					onChange={ v => onParamChange( v )}
				/>
				
				<Button 
					extra_classes="yellow" 
					onClick={() => onSubmit && onSubmit( params )}
				> { translate( "search" )} </Button>
			</div>
		</div>
	) : <div className="search-panel preload"/>
}