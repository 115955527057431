import { Link } from "react-router-dom";
import Paths from "routes/Paths";
import { useTranslate } from "context/LanguageContext";

import Time from "components/general/Time"; 
import "./notification.scss";

export default function Notification({ id, offer_title, time }) {
	
	const translate = useTranslate(); 

	return (
		<Link to={ Paths.Offer( id )} className="notification">
			{ translate( "offer_notification", offer_title )}
			<Time time={ time }/>
		</Link>
	)
}