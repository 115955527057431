import PostsLoop from "components/general/PostsLoop";
import Notification from "../Notification"

export default function NotificationsLoop( props ) {
	
	return (
		<PostsLoop
			{...props }
			empty_text="empty_notifications"
			Component={ Notification }
		/>
	)
}
	