import { useState, useMemo, forwardRef } from "react";
import { useTranslate } from "context/LanguageContext";
import useDidChange from "hooks/useDidChange";
import withInnerPopup from "hoc/withInnerPopup";
import useCalendarDate from "hooks/useCalendarDate";
import { getCalendarDayData } from "extra/date";

import Checkbox from "components/form/Checkbox";
import Arrows from "components/buttons/Arrows";
import Options from "components/form/Options"; 
import Calendar from "components/general/Calendar";
import { CalendarSvg, CrossSvg } from "svg";

import "./styles/date-range.scss";
import "./styles/date-range-responsive.scss";

const DateRange = forwardRef(( props, ref ) => {

	const translate = useTranslate();
	const { show_popup, setPopup } = props;
	const { default_value, extra_classes = "", onChange } = props;
	const [ calendar_date, changeDate ] = useCalendarDate();

	const [ range, setRange ] = useState({
		from: default_value?.from ? getCalendarDayData( new Date( default_value.from )) : null,
		to: default_value?.to ? getCalendarDayData( new Date( default_value.to )) : null
	});
	
	const [ open_date, setOpenDate ] = useState( default_value?.open_date || false );
	const [ tour_length, setTourLength ] = useState( default_value?.tour_length || null );
	
	const second_calendar_date = useMemo(() => {

		const next_month = calendar_date.month === 12 ? 1 : calendar_date.month + 1;
		const year = next_month === 1 ? calendar_date.year + 1 : calendar_date.year;

		return { month: next_month, year: year };

	}, [ calendar_date ])


	const onDayClick = day => {

		const { from, to } = range;

		if (( !from && !to ) || ( from && to ) || ( !from && to )) {
			setRange({ from: day });
			return;
		}

		if ( from && !to ) {
			setRange( from.date_ms <= day.date_ms
				? { from, to: day }
				: { from: day, to: from }
			)
		}
	}


	const calendar_props = {
		calendar_date: calendar_date,
		range: range,
		onDayClick: onDayClick
	}


	useDidChange(() => { onChange && onChange({
		tour_length: tour_length,
		open_date: open_date,
		date_from: range.from?.date_iso,
		date_to: range.to?.date_iso
	})}, [ range, tour_length, open_date ])


	return (
		<div ref={ ref } className={`date-range ${ extra_classes }`}>

			<div className="date-range-main" onClick={() => setPopup( !show_popup )}>
				<span> { translate( "when" )} </span>
				<strong> { range.from?.date || "" } { range.to?.date ? ` - ${ range.to.date }` : "" } </strong>

				{( range.from || range.to ) && 
					<a href="#" onClick={ e => {
						e.preventDefault();
						setRange({ from: null, to: null })
					}}>
						<CrossSvg/>
					</a>
				}
				<CalendarSvg/>
			</div>

			{ show_popup && 
				<div className="date-range-popup">
					
					<div style={{ fontWeight: "600" }}> 
						{ translate( "select_travel_start_date_range" )}
					</div>

					<div className="date-range-calendars"> 

						<Arrows onClick={ changeDate }/>

						<Calendar {...calendar_props }/>
						<Calendar {...calendar_props } calendar_date={ second_calendar_date }/>
					</div>

					<div style={{ marginBottom: "20px" }}> 
						<Checkbox
							name="open_date"
							checked={ !!open_date }
							onClick={( name, c ) => setOpenDate( c )}
						>
							<span title={ translate( "open_date_explain" )}> 
								{ translate( "individual_term" )}
							</span>
						</Checkbox>
					</div>

					<Options
						label={ translate( "specify_length_of_trip" )}
						default_value={ tour_length }
						options={[
							{ label: "any", value: "any" },
							{ label: "1_6_days", value: "1-6" },
							{ label: "7_10_days", value: "7-10" },
							{ label: "11_14_days", value: "11-14" },
							{ label: "more_than_14_days", value: "14-more" }
						]}
						onChange={( n, v ) => setTourLength( v )}
					/>
				</div>
			}
		</div>
	)
});

export default withInnerPopup( DateRange );