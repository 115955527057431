
export const isFunction = fn => toString.call( fn ) === "[object Function]";

export const isString = str => toString.call( str ) === "[object String]";

export const isObject = obj => toString.call( obj ) === "[object Object]";

export const addZero = num => +num > 9 ? num : "0" + num;

export const getSearchRegExp = str => new RegExp( str.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&" ), "gi" );

export const scrollSmoothTo = DOMElement => {

	const top = DOMElement ? DOMElement.getBoundingClientRect().top + window.scrollY - 10 : 0;

	"scrollBehavior" in document.documentElement.style
		? window.scrollTo({ top: top, behavior: 'smooth' })
		: window.scrollTop = top;
}


export const toggleArrValue = ( value, arr ) => {
	return arr.includes( value )
		? arr.filter( v => v !== value )
		: [...arr, value ]
}


export const getURLSearchFromObject = obj => {

	const url_search = new URLSearchParams();

	Object.entries( obj ).forEach(([ key, value ]) => {
		
		if ( !value ) return;
		if ( Array.isArray( value ) && !value.length ) return;

		url_search.set( key, value )	
	})

	return url_search.toString();
}


export const cropString = ( str, max_length ) => {
	return str.length > max_length
		? str.slice( 0, max_length ) + "..."
		: str
}


export const onShareLinkClick = e => {
	e.preventDefault();

	const link_el = e.target.closest("a");
	const href = link_el.getAttribute( "href" );

	window.open( href, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600" );
	return false;
}


export const getDebounceFn = ( fn, delay = 300 ) => {

	let timer;

	return function( ...args ) {

		clearTimeout( timer ); 	
		timer = setTimeout(() => {
			fn && fn( ...args )
		}, delay )
	}
}


export const loadScript = options => {
	
	if ( !options.src ) return;
	if ( document.getElementById( options.id )) return;

	const script = document.createElement('script');
	script.id  = options.id;
	script.src = options.src;
	script.type = options.type || "text/javascript";
	script.async = options.async || true;
	script.onload  = options.onload;

	const place = options.place || document.body; 
	place.appendChild( script );
}


export const waitYTPlayer = ( ms = 300 ) => new Promise(( resolve ) => {

	const check = () => {
		!!window.YT?.Player 
			? resolve() 
			: setTimeout(() => check(), ms )
	};

	check();
})


export const getDecodedString = str => {
    const txt = document.createElement( "textarea" );
    txt.innerHTML = str;
    return txt.value;
};



export const isTheEndOfScrollContainer = container => {

	const scroll_top = container.offsetHeight + container.scrollTop;
	const max = container.scrollHeight;

	return scroll_top >= max;
}