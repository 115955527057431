import { useTranslate } from "context/LanguageContext";

export default function({ email, phone }){

	const translate = useTranslate();

	return (
		<div className="footer-contact">
			<h4> { translate( "contact_2" )} </h4>

			<div>
				{ phone && 
					<a href={`tel:${ phone.replace(/[- ()]/g, "" )}`}> { phone } </a> 
				}

				{ email && 
					<a href={`mailto:${ email.trim()}`}> { email } </a>
				}
			</div>
		</div>
	)
}