import { Suspense, lazy } from "react";
import { Route, Routes as Switch } from "react-router-dom"
import Paths from "./Paths";

import Loader from "components/general/Loader";
import ProfileLayout from "layouts/ProfileLayout";
import NotFoundPage from "pages/NotFoundPage";

const MainPage = lazy(() => import( "pages/MainPage" ));
const ContactPage = lazy(() => import( "pages/ContactPage" ));
const SearchPage = lazy(() => import( "pages/SearchPage" ));
const OfferPage = lazy(() => import( "pages/OfferPage" ));
const TravelMediaPage = lazy(() => import( "pages/TravelMediaPage" ));
const ArticlePage = lazy(() => import( "pages/ArticlePage" ));
const AboutUsPage = lazy(() => import( "pages/AboutUsPage" ));
const InsurancePage = lazy(() => import( "pages/InsurancePage" ));

const SavedSearchPage = lazy(() => import( "pages/profile/SavedSearchPage" ));
const SettingsPage = lazy(() => import( "pages/profile/SettingsPage" ));
const CreateOwnTripPage = lazy(() => import( "pages/profile/CreateOwnTripPage" ));
const FavoriteTripsPage = lazy(() => import( "pages/profile/FavoriteTripsPage" ));
const NotificationsPage = lazy(() => import( "pages/profile/NotificationsPage" ));
const NotificationSettingsPage = lazy(() => import( "pages/profile/NotificationSettingsPage" ));

const PortfolioPage = lazy(() => import( "pages/PortfolioPage" ));
const PrivacyPolicyPage = lazy(() => import( "pages/PrivacyPolicyPage" ));
const DirectionsPage = lazy(() => import( "pages/DirectionsPage" ));
const FAQPage = lazy(() => import( "pages/FAQPage" ));
const RegulationsPage = lazy(() => import( "pages/RegulationsPage" ));
const DataProtectionPage = lazy(() => import( "pages/DataProtectionPage" ));
const DataDeletionPolicyPage = lazy(() => import( "pages/DataDeletionPolicyPage" ));

const Pages = [
	{
		path: Paths.Main,
		Component: MainPage
	},
	{
		path: Paths.Contact,
		Component: ContactPage
	},
	{
		path: Paths.Search,
		Component: SearchPage
	},
	{
		path: Paths.Sale,
		Component: SearchPage
	},
	{
		path: Paths.BusinessTrips,
		Component: SearchPage
	},
	{
		path: Paths.Offer(),
		Component: OfferPage
	},
	{
		path: Paths.TravelMedia,
		Component: TravelMediaPage
	},
	{
		path: Paths.Article(),
		Component: ArticlePage
	},
	{
		path: Paths.AboutUs,
		Component: AboutUsPage
	},
	{
		path: Paths.Insurance,
		Component: InsurancePage
	},
	{
		path: Paths.Portfolio,
		Component: PortfolioPage
	},
	{
		path: Paths.PrivacyPolicy,
		Component: PrivacyPolicyPage
	},
	{
		path: Paths.Directions,
		Component: DirectionsPage
	},
	{
		path: Paths.FAQ,
		Component: FAQPage
	},
	{
		path: Paths.Regulations,
		Component: RegulationsPage
	},
	{
		path: Paths.DataProtection,
		Component: DataProtectionPage
	},
	{
		path: Paths.DataDeletionPolicy,
		Component: DataDeletionPolicyPage
	}
]


const ProfilePages = [
	{
		path: Paths.Profile.SavedSearch,
		title: "saved_searches",
		Component: SavedSearchPage,
		Layout: ProfileLayout
	},
	{
		path: Paths.Profile.Settings,
		title: "profile_settings",
		Component: SettingsPage,
		Layout: ProfileLayout
	},
	{
		path: Paths.Profile.CreateOwnTrip,
		title: "create_own_trip",
		Component: CreateOwnTripPage,
		Layout: ProfileLayout
	},
	{
		path: Paths.Profile.Favorites,
		title: "favorite_trips",
		Component: FavoriteTripsPage,
		Layout: ProfileLayout
	},
	{
		path: Paths.Profile.Notifications,
		title: "notifications",
		Component: NotificationsPage,
		Layout: ProfileLayout
	},
	{
		path: Paths.Profile.NotificationSettings,
		title: "notification_settings",
		Component: NotificationSettingsPage,
		Layout: ProfileLayout
	}
]


export default function Routes({ authorized }) {

	const pages = authorized ? [ ...Pages, ...ProfilePages ] : Pages;

	return (
		<Switch>
			{ pages.map(({ path, title, Component, Layout = "div" }) => (
				<Route key={ path } path={ path } element={ 
					<Layout title={ title }> 
						<Suspense fallback={ <Loader/> }> 
							<Component/> 
						</Suspense>
					</Layout> 
				}/>
			))}

			<Route path="*" element={ <NotFoundPage/> }/>
		</Switch>
	)
}