import { useTranslate } from "context/LanguageContext";

export default function AuthBottom({ text, button_label, onClick }) {

	const translate = useTranslate();
	
	return (
		<div className="auth-bottom">
			<span> { translate( text )} </span>
			<button onClick={ onClick }> { translate( button_label )} </button>
		</div>
	)
}