import { InstagramSvg, FacebookSvg, TwitterSvg, YoutubeSvg } from "svg/socials";
import "./socials.scss";

export default function Socials({ items, heading, onLinkClick }) {
	
	const getSocialSvg = href => {

		if ( /instagram/.test ( href )) return <InstagramSvg/>
		if ( /facebook/.test( href )) return <FacebookSvg/>
		if ( /twitter/.test( href )) return <TwitterSvg/>
		if ( /youtube/.test( href )) return <YoutubeSvg/>

		return ""
	}

	return (
		<div className="socials-box">
			{ heading && <h4> { heading } </h4>}
			
			<ul className="socials">
				{ items && !!items.length &&
					items.map(( item, i ) => (
						<li key={ i }>
							<a 
								href={ item.href } 
								title={ item.title } 
								target="_blank"
								onClick={ onLinkClick || null }	
							> 
								{ getSocialSvg( item.href )}
							</a>
						</li>
					))
				}
			</ul>
		</div>
	)
}