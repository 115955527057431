import API from "./API";

export default {
	header: () => API.get( "/general/header" ),
	footer: () => API.get( "/general/footer" ),
	subscribeNewsletter: email => API.post( "/newsletter", { email }),
	unsubscribeNewsletter: unsubscribe  => API.post( "/newsletter/unsubscribe", { unsubscribe  }),
	contactForm: data => API.post( "/contact", {
		...data,
		total_persons: ( data.kids || 0 ) + ( data.adults || 0 )
	})
}