import { PlaneSvg, BusSvg, CarSvg } from "svg/transport";

export default [
	{
		label: {
			en: "Airplane",
			pl: "Samolot"
		},
		value: "Airplane",
		svg: <PlaneSvg/>
	},
	{
		label: {
			en: "Bus",
			pl: "Autobus"
		},
		value: "Bus",
		svg: <BusSvg/>
	},
	{
		label: {
			en: "Own transport",
			pl: "Dojazd własny"
		},
		value: "Own",
		svg: <CarSvg/>
	}
]