import { useState, useEffect } from 'react';

export default function useWindowResize( fn ) {
	
	const [ state, setState ] = useState( fn() || null );

	useEffect(() => {

		if ( !fn ) return;
		const getState = () => setState( fn());
		getState();

		window.addEventListener( "resize", getState );
		return () => window.removeEventListener( "resize", getState );
	}, [])

	return state
}