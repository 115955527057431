import { PlusBoldSvg } from "svg";
import withModal from "hoc/withModal";

import "./extra-tooltip.scss";

const ExtraTooltip = ({ items, heading, openModal }) => {
		
	return items && !!items?.length ?  (
		<div 
			className="extra-tooltip"
			onClick={ e  => {
				e.preventDefault();
				e.stopPropagation();
				
				openModal({
					heading: heading,
					extra_classes: "extra-tooltip-modal",
					onModalClick: e => e.preventDefault(),
					children: <ul className="extra-tooltip-items">
								{ items && !!items.length &&
									items.map( item => (
										<li key={ item }> { item } </li>
									))
								}
							</ul>
				})
			}}
		>
			<PlusBoldSvg/>
		</div>
	) : null
};

export default withModal( ExtraTooltip );