import { Link } from "react-router-dom";
import { LogoSvg } from "svg";
import "./logo.scss";

export default function Logo({ svg }) {
		
	return (
		<Link className="logo" to="/">
			{ svg && <LogoSvg/> }
			<span> TRIPUJ. </span>
			<strong> PL </strong>
		</Link>
	)
}