import { getDecodedString } from "extra/functions";
import { getAllImgSizes } from "./general";

export default function( offer ) {
	
	const acf = offer.acf;
	const places = acf?.places;
	const organizer_offer_description = acf?.opis_oferty_organizatora;
	const amenities = acf?.udogodnienia;
	const gallery = acf?.galeria;
	const categories = offer.terms?.kategoria_oferty;
	const attributes = offer.terms?.atrybuty;
	const place_on_map = acf?.miejsce_na_mapie;
	const places_on_map = acf?.miejsca_na_mapie;

	const offer_images = getAllImgSizes( offer.thumbnail );

	return {
		id: offer.ID,
		title: getDecodedString( offer.post_title ),
		organizer_id: offer.post_author,
		rating: acf?.rating || 0,
		average_rating: acf?.avg_rating,

		current_travel_conditions: acf?.current_travel_conditions,

		road_trip: acf.road_trip,	

		places: Array.isArray( places ) 
			? places.map( i => ({...i, country: i.country.post_title }))
			: null,

		place_on_map: place_on_map && place_on_map?.city_on_map ? {
			city: place_on_map.city,
			lat: place_on_map.city_on_map.lat,
			lng: place_on_map.city_on_map.lng,
			pin_color: place_on_map?.pin_color,
			icon: place_on_map?.ikona,
			position: [ place_on_map.city_on_map.lat, place_on_map.city_on_map.lng ] 
		} : null,	

		places_on_map: Array.isArray( places_on_map )
			? places_on_map
				.filter( place => !!place.city_on_map )
				.map( place => ({
					city: place.city,
					lat: place.city_on_map.lat,
					lng: place.city_on_map.lng,
					pin_color: place?.pin_color,
					icon: place?.ikona,
					position: [ place.city_on_map.lat, place.city_on_map.lng ] 
				}))
			: null,

		details: {
			dining: acf.wyzywienie?.post_title || "",
			hotel_standard: acf.standard_hotelu,
			no_passport: acf.no_passport,
			open_borders: acf.open_borders
		},
		
		transport: acf.srodek_transportu?.value,

		info_from_organizer: acf?.informacje_od_organizatora,

		organizer_offer_description: organizer_offer_description ? {
			number: organizer_offer_description?.numer_terminu,
			description: organizer_offer_description?.opis
		} : {},

		...offer_images,

		/* array {
			sale;
			total_seats;
			start_date;
			end_date;
			adult_price
			child_price;
			sale_adult_price:
			sale_child_price;
		} */
		dates: Array.isArray( acf?.termin )
			? acf.termin.map( d => { 
				
				const empty_date = d.open_date || ( !d.start_date && !d.end_date ); 

				return {
					sale: d.sale,
					total_seats: d.total_seats,
					start_date: empty_date ? d.start_date : null,
					end_date: empty_date ? d.end_date : null,
					date_label: !empty_date ? d.start_date + " - " + d.end_date : "ask_for_available_dates",
					empty_price: !d.adult_price && !d.child_price && !d.sale_adult_price && !d.sale_child_price,
					
					price: {
						adult: d.adult_price ? d.adult_price * 100 : 0,
						child: d.child_price ? d.child_price * 100 : 0,
						adult_sale: d.sale && d.sale_adult_price ? d.sale_adult_price * 100 : 0,
						child_sale: d.sale && d.sale_child_price ? d.sale_child_price * 100 : 0
					}
				}
			})
			: [{}],

		one_person_price: acf?.termin?.[0]
			? acf?.termin?.[0]?.sale ? acf?.termin?.[0].sale_adult_price : acf?.termin?.[0].adult_price
			: "0",

		amenities: Array.isArray( amenities )
			? amenities.map( amenity => ({
				value: amenity.ID,
				label: amenity.post_title,
				icon_url: amenity.acf?.ikona?.url
			}))
			: null,

		gallery: Array.isArray( gallery )
			? [ offer_images, ...gallery.map( g => getAllImgSizes( g ))]
			: [ offer_images ],

		categories: Array.isArray( categories )
			? categories.map( c => c.name ).join(", ")
			: null,
		
		attributes: Array.isArray( attributes )
			? attributes.map( a => a.name ).join(", ")
			: null,

		reviews: Array.isArray( offer.comments ) && !!offer.comments.length 
			? offer.comments.map( c => ({
				user_name: c.comment_author,
				user_avatar: "",
				text: c.comment_content,
				rating: c.acf?.rating
			}))
			: null	
	}
}