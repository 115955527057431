import useLocationDidChange from "hooks/useLocationDidChange";
import useFormData from "hooks/useFormData";
import useOfferAttributes from "hooks/Offer/useOfferAttributes";
import useDidChange from "hooks/useDidChange";
import Paths from "routes/Paths";

export default function withSearchParams( Component ) {
		
	return function( props ) {
		
		const attributes = useOfferAttributes();
		const { data, changeData, setNullData } = useFormData( null );

		const updateParams = () => {
			setNullData();

			const init_params = {
				page: 1,
				sort: "most_popular",
				transport: [ "Airplane", "Bus", "Own" ]
			}
			
			const url_search = new URLSearchParams( window.location.search );
	
			url_search.forEach(( v, key ) => {

				let value = v;
				
				if ([ 
					"transport", "categories", "departure_attr", 
					"continent", "where", "organizer", "amenities", "dining"
				].includes( key )) value = v.split(",")

				if ([ "sale", "show_unrated", "no_passport", "open_borders"].includes( key )) 
					value = v === "true";

				init_params[ key ] = value;	
			})

			if ( window.location.pathname === Paths.Sale ) 
				init_params.sale = true;
	
			if ( window.location.pathname === Paths.BusinessTrips )
				init_params.departure_attr = [ attributes.find( a => a.label === "Wyjazdy firmowe" )?.value ]

			setTimeout(() => changeData( init_params ), 300 )
		}

		
		useLocationDidChange(() => {
			attributes && updateParams();
		});


		useDidChange(() => {
			attributes && !data && updateParams();
		}, [ attributes ])


		return ( 
			<Component {...props } params={ data } onParamChange={ changeData } /> 
		)
	}
}