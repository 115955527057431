export default {
	
	Main: "/",
	Sale: "/sale",
	Insurance: "/insurance",
	TravelMedia: "/travel-media",
	BusinessTrips: "/business-trips",
	AboutUs: "/about-us",
	Contact: "/contact",
	PrivacyPolicy: "/privacy-policy",
	DataProtection: "/data-protection",
	DataDeletionPolicy: "/data-deletion-policy",
	FAQ: "/faq",
	Portfolio: "/portfolio",
	Directions: "/directions",
	Regulations: "/regulations",
	Search: "/search",
	NotFound: "/404",

	Offer: ( id = ":id" ) => `/offer/${ id }`,
	Article: ( id = ":id" ) => `/article/${ id }`,

	Profile: {
		SavedSearch: "/profile/saved-search",
		Settings: "/profile/settings",
		CreateOwnTrip: "/profile/create-own-trip",
		Favorites: "/profile/favorite-trips",
		Notifications: "/profile/notifications",
		NotificationSettings: "/profile/notification-settings"
	}
}