import { useTranslate } from "context/LanguageContext";
import Paths from "routes/Paths";

import FavoriteOffersLoop from "components/offer/FavoriteOffersLoop";
import Button from "components/buttons/Button";

export default function FavoritesPopup({ favorites }) {
	
	const translate = useTranslate();

	return (
		<>
			<FavoriteOffersLoop items={ favorites } max_items={ 3 }/>
			
			{ favorites && favorites.length > 3 &&	
				<Button 
					to={ Paths.Profile.Favorites } 
					extra_classes="yellow small"
				>
					{ translate( "see_all" )} 
				</Button>
			}
		</>
	)
}