import CountryAPI from "api/CountryAPI";
import useFetchData from "hooks/useFetchData";

export default function( continent_id, offer_count ) {
	return useFetchData({
		APIMethod: CountryAPI,
		cache_name: "countries",
		only_data: true,
		dependencies: [ continent_id ],

		responseDataItemHandler: country => {
			return {
				value: String( country.ID ),
				label: country.post_title,
				offer_count: +country.acf?.offer_count,
				continent: String( country.acf?.continent )
			}
		},

		filterHandler: data => {

			let filtered = [...data ];

			if ( offer_count )
				filtered = filtered.filter( country => !!country.offer_count )
				
			return continent_id 
				? filtered.filter( country => {
					return Array.isArray( continent_id )
						? continent_id.includes( country.continent )
						: continent_id === country.continent
				})
				: filtered
		}
	})
}