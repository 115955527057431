export default {

	"individual_heading_1": {
		"en": "Describe your trip",
		"pl": "Opisz swój wyjazd"
	},

	"individual_heading_2": {
		"en": "Do you know a specific departure date?",
		"pl": "Znasz konkretną datę wyjazdu?"
	},

	"individual_text_1": {
		"en": "Mark your own criteria on the basis of which we will create a unique trip",
		"pl": "Zaznacz własne kryterię na podstawie których my stworzymy unikalną wyciczkę"
	},

	"individual_text_2": {
		"en": "individually for You!",
		"pl": "indywidualnie dla Ciebie!"
	}
}