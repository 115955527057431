import { useState } from "react";
import Modal from "components/modal/Modal";

export default function withModal( Component, ModalComponent = Modal ) {
		
	return function( props ) {
		
		const [ modal, setModal ] = useState( null );

		return (
			<> 
				<Component 
					{...props } 
					openModal={( o = {}) => setModal( o )}
				/>
				
				{ modal && 
					<ModalComponent 
						{ ...modal }
						onClose={() => setModal( false )}
					/>
				}
			</>
		)
	}
}