import { useState } from "react";
import { isObject } from "extra/functions";

export default function useFormData( init_data = {} ) {

	const [ data, _setData ] = useState( init_data );

	const clearData = () => _setData({});

	const setNullData = () => _setData( null );

	const changeData = ( name, value ) => {
		isObject( name )
			? _setData( d => ({...d, ...name }))
			: _setData( d => ({...d, [ name ]: value }))
	}	

	return { data, changeData, clearData, setNullData };
}