import GeneralAPI from "api/GeneralAPI";
import useFetchData from "../useFetchData";

export default function() {
	return useFetchData({
		APIMethod: GeneralAPI.footer,
		cache_name: "footer",
		only_data: true,
		data_param_name: "acf",
		
		responseDataHandler: footer => ({
			contact_details: footer.contact_details || {},
			footer_menu: footer.footer_menu 
				? footer.footer_menu.map( item => {
					
					const is_relative = item.url[0] === "/"; 

					return {
						label: item.tytul,
						path: is_relative ? item.url : null,
						href: is_relative ? null : item.url,
						target: is_relative ? null : "_blank"
					}
				})
				: null,

			socials: footer.social_media_seaction?.social_media 
				? footer.social_media_seaction?.social_media.map( i => ({ href: i.url }))
				: null 
		})
	})
}