import { useState, useEffect } from "react";
import { useUser } from "context/AuthContext";
import useLocationDidChange from "hooks/useLocationDidChange";
import { useTranslate } from "context/LanguageContext";

import Logo from "components/general/Logo";
import MainMenu from "components/navigation/MainMenu";
import FavoritesWidget from "components/widgets/FavoritesWidget";
import NotificationsWidget from "components/widgets/NotificationsWidget";
import LanguageSwitcher from "components/widgets/LanguageSwitcher";
import UserWidget from "components/widgets/UserWidget";
import MobileMenuButton from "components/buttons/MobileMenuButton";
import Socials from "components/general/Socials";

import "./styles/header.scss";
import "./styles/header-responsive.scss";

export default function Header() {
	
	const translate = useTranslate();
	const user = useUser();
	const [ mobile_menu_open, setMobileMenuOpen ] = useState( false );

	useLocationDidChange(() => setMobileMenuOpen( false ));

	useEffect(() => {
		const overflow = mobile_menu_open ? "hidden" : "";
		document.body.style.overflow = overflow;
	}, [ mobile_menu_open ]);


	return (
		<header className={`header ${ mobile_menu_open ? "mobile-menu-open" : "" }`}>
			<div className="container"> 
				<Logo svg/>
				<MainMenu/>

				<div className="header-right">
					{ user &&
						<>
							<FavoritesWidget/>
							<NotificationsWidget/>
						</>
					}
					
					<LanguageSwitcher/>
					<UserWidget/>

					<MobileMenuButton 
						open={ mobile_menu_open }
						onClick={() => setMobileMenuOpen( !mobile_menu_open )}
					/>
				</div>

				<Socials heading={ translate( "watch_us" )} items={[
					{ href: "https://instagram.com" },
					{ href: "https://www.facebook.com/" },
					{ href: "https://twitter.com/" },
					{ href: "https://www.youtube.com/" }
				]}/>
			</div>
		</header>
	)
}