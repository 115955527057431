import axios from "axios";
import Storage from 'extra/Storage';

const API = axios.create({
	baseURL: window.config.ORIGIN + "/api/index.php/wp-json/api/v2" 
})


API.interceptors.request.use( config => {
	
	const token = Storage.get( "token" );
	if ( token ) config.headers.Authorization = `Bearer ${ token }`;

	const lang = Storage.get( "locale" ) || "pl";
	config.headers.lang = lang;

	return config;
}, error => Promise.reject( error ));

export default API;