import { useTranslate } from "context/LanguageContext";
import withSearchParams from "hoc/withSearchParams";
import SearchPanel from "components/search/SearchPanel";
import "./not-found-section.scss";

function NotFoundSection( props ) {
	
	const translate = useTranslate();

	return (
		<div className="not-found-section">
			<div className="container">

				<div className="not-found-section-top">

					<div> 
						<img src={ require( "img/404.png" )} alt="ooops! 404" />
					</div>

					<div> 
						<img src={ require( "img/not-found.png" )} alt="not found"/>
					</div>
				</div>

				<h2> { translate( "page_not_found" )} </h2>
				<p> { translate( "not_found_text" )} </p>

				<SearchPanel 
					to_search_page 
					{...props } 
				/>
			</div>
		</div>
	)
}

export default withSearchParams( NotFoundSection ); 