import { useState, useMemo } from "react";

import { useTranslate } from "context/LanguageContext";
import Loader from "components/general/Loader";
import Pagination from "../Pagination";

export default function PostsLoop({ 
	items,
	empty_text,
	extra_component_props = {},
	per_page = 4,
	max_items = 999,
	Component
}) {

	const translate = useTranslate();	
	const [ page, setPage ] = useState(1);

	const all_items = items ? items.slice( 0, max_items ) : [];	
	const pages_amount = items ? Math.ceil( all_items.length / per_page ) : 0;

	const current_items = useMemo(() => (
		all_items.slice(( page - 1 ) * per_page , page * per_page )
	), [ page, pages_amount, all_items, per_page ]);


	if ( !Component ) return null;

	return items 
		? !!items.length
			? (<>
				{ current_items.map(( item, i ) => (
					<Component key={ item.id || i } {...item } {...extra_component_props }/> 
				))}

				{ pages_amount > 1 && 
					<Pagination
						page={ page }
						pages_amount={ pages_amount }
						onChange={ p => setPage( p )}
					/> 
				}
			</>)
			: (
				<div style={{ width: "280px", padding: "10px", textAlign: "center", margin: "0 auto" }}> 
					{ translate( empty_text )} 
				</div>
			)
		: <Loader/>
}