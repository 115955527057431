import Paths from "routes/Paths";
import { useTranslate } from "context/LanguageContext";

import Button from "components/buttons/Button";
import NotificationsLoop from "../NotificationsLoop";

export default function NotificationsPopup({ notifications }) {		

	const translate = useTranslate();

	return (
		<>
			<NotificationsLoop
				items={ notifications }
				max_items={ 3 }
				extra_component_props={{ max_text_length: 64 }}
			/> 

			{ notifications && !!notifications?.length > 3 && 
				<Button 
					to={ Paths.Profile.Notifications } 
					extra_classes="yellow small"	
				> 
					{ translate( "see_all" )} 
				</Button>
			}
		</>
	)
}