import { createContext, useContext, useState } from "react";
import Storage from "extra/Storage";
import Translations from "translations";
import { PlSvg, UkSvg } from "svg/locale";
import { isFunction, isObject } from "extra/functions";

const LanguageContext = createContext();

export const languages = [
	{ locale: "pl", svg: <PlSvg/> },
	{ locale: "en", svg: <UkSvg/> }
]


export default function LanguageContextProvider({ children }) {

	const [ locale, setLocale ] = useState( Storage.get( "locale" ) || "pl" ); 

	const changeLocale = new_locale => {
		Storage.set( "locale", new_locale );
		setLocale( new_locale );
	}


	const translate = ( key, ...replace_words ) => {

		if ( isObject( key ))
			return key?.[ locale ] || "";

		const translation = Translations[ key ];
		if ( !translation ) return key;

		if ( isObject( translation ))
			return translation[ locale ] || key;

		if ( isFunction( translation ))
			return translation(...replace_words )[ locale ] || key
	} 

	
	return (
		<LanguageContext.Provider value={{ 
			locale, 
			changeLocale,
			translate	 
		}}>
			{ children }
		</LanguageContext.Provider>
	)
}

export const useLocale = () => {
	const { locale, changeLocale } = useContext( LanguageContext );
	return { locale, changeLocale }
}

export const useTranslate = () => useContext( LanguageContext ).translate;