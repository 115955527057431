import useOfferCategories from "hooks/Offer/useOfferCategories";

import CategoriesOptions from "./components/CategoriesOptions";
import Dropdown from "../Dropdown";

export default function CategoriesDropdown( props ) {
	
	const categories_options = useOfferCategories();
	// console.log( categories_options );
	return (
		<Dropdown
			label="category"
			search_placeholder="departure_category"
			options={ categories_options }
			OptionsComponent={ CategoriesOptions }	
			{...props }
		/>
	)
}