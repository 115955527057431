import { useState, useMemo, forwardRef } from "react";
import useDidChange from "hooks/useDidChange";
import { getSearchRegExp, toggleArrValue } from "extra/functions";
import withInnerPopup from "hoc/withInnerPopup";
import { useTranslate } from "context/LanguageContext";

import DropdownChosen from "./components/DropdownChosen";
import DropdownOptions from "./components/DropdownOptions";
// import { CrossSvg } from "svg";

import "./styles/dropdown.scss";
import "./styles/dropdown-responsive.scss";

const Dropdown = forwardRef(( props, ref ) => {
	
	const translate = useTranslate();
	const { show_popup, setPopup } = props;
	const { 
		name,
		label = "", 
		extra_classes = "",
		options, 
		disabled,
		update_chosen_on_options_change,
		search_placeholder = "", 
		options_component_props = {}, 
		OptionsComponent = DropdownOptions, 
		onChange 
	} = props;

	const [ chosen, setChosen ] = useState( props.default_value || []);
	const [ search_str, setSearchStr ] = useState("");

	const chosen_labels = useMemo(() => (
		Array.isArray( chosen ) && Array.isArray( options )
			? chosen.map( value => { 

				for ( let i = 0; i < options.length; i++ ) {
					const option = options[i];

					if ( option.value === value ) return option

					if ( option.subitems && !!option.subitems.length ) {
						for ( let j = 0; j < option.subitems.length; j++ ) {
							const subitem = option.subitems[j]; 
							if ( subitem.value === value ) return subitem;
						}
					}
				}
			})
			: null
	), [ chosen, options ]);


	const options_result = useMemo(() => (
		search_str && Array.isArray( options )
			? options.filter( option => {
				
				const reg_exp = getSearchRegExp( search_str );
				if ( reg_exp.test( option.label )) return true;

				if ( !!option.subitems?.length ) {
					return !!option.subitems.filter( sub => reg_exp.test( sub.label )).length;
				}

				return false;
			})
			: options
	), [ search_str, options ])

	
	useDidChange(() => { onChange && onChange( name, chosen )}, [ chosen ])

	useDidChange(() => {
		update_chosen_on_options_change && !!chosen.length && setChosen( chosen.filter( c => options.find( opt => opt.value === c ) ));
	}, [ options ])


	return Array.isArray( options ) ? (
		<div ref={ ref } className={`dropdown ${ disabled ? "disabled" : "" } ${ extra_classes }`}>

			<div 
				className="dropdown-main" 
				onClick={() => !disabled && setPopup( !show_popup )
			}>
				<span> { translate( label )} </span>

				{ chosen_labels && !!chosen_labels.length &&
					chosen_labels.map(( cl = {}, i ) => (
						<DropdownChosen 
							key={ cl?.value || i }
							{...cl }
							setChosen={ setChosen }
						/>
					))
				}
			</div>

			{ show_popup && 
				<div className="dropdown-popup">

					{/* <button onClick={() => setPopup( false )}>
						<CrossSvg/>
					</button> */}

					<input 
						type="text" 
						className="dropdown-search" 
						value={ search_str }
						placeholder={ translate( search_placeholder )}
						onChange={ e => setSearchStr( e.target.value )}	
					/>

					{ search_str && !options_result.length && 
						<div style={{ padding: "0 10px" }}> Brak wyników </div> 
					}

					{ OptionsComponent && 
						<OptionsComponent
							{...options_component_props }
							search_str={ search_str }
							options={ options_result }
							chosen={ chosen }
							setChosen={ value => {
								setChosen( toggleArrValue( value, chosen ))
							}}
						/> 
					}
				</div>
			}
		</div>
	) : (
		<div className={`dropdown ${ extra_classes }`}> 
			<div className="dropdown-main"> 
				{ translate( "loading..." )} <span/> 
			</div> 
		</div>
	)
})

export default withInnerPopup( Dropdown );