import { AngleLeftSvg } from "svg";
import "./back-button.scss";

export default function BackButton({ onClick }) {
		
	return (
		<button 
			type="button" 
			className="back-button"
			onClick={ onClick }	
		>
			<AngleLeftSvg/>
		</button>
	)
}