import { NavLink } from "react-router-dom";
import { useTranslate } from "context/LanguageContext";

export default function Menu({ className = "", links }) {
		
	const translate = useTranslate();

	return (
		<nav className={ className }>
			{ links && !!links.length &&
				links.map(({ label, svg, path, hide, ...rest }, i ) => {

					const Component = path ? NavLink : "a";
					
					const link_props = path ? {
						to: path,
						className: ({ isActive }) => isActive ? "active" : ""
					} : rest

					return !hide ? (
						<Component 
							key={ path || i }
							{...link_props }	
						> 
							{ svg }
							{ translate( label )} 
						</Component>
					) : null

				})
			}
		</nav>
	)
}