import { useState } from "react";

export default function useCalendarDate( init_date ) {
		
	const [ calendar_date, setCalendarDate ] = useState( init_date || {
		month: new Date().getMonth() + 1,
		year: new Date().getFullYear()
	});


	const changeDate = action => {
	
		let month, year;
		const cd = calendar_date;

		switch ( action ) {
			case "prev":
				month = cd.month === 1 ? 12 : cd.month - 1;
				year = month === 12 ? cd.year - 1 : cd.year;
				break;

			case "next":
				month = cd.month === 12 ? 1 : cd.month + 1;
				year = month === 1 ? cd.year + 1: cd.year;
				break;	

			default: return;	
		}

		setCalendarDate({ month,year });
	}

	return [ calendar_date, changeDate ];
}