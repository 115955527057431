import { getDecodedString } from "extra/functions";
import useTerms from "hooks/useTerms";

export default function() {
	return useTerms( "kategoria_oferty", data => {

		const categories = data.filter( t => !t.parent );
		const subcategories = data.filter( t => !!t.parent ); 
	
		return categories.map( c => ({
			value: String( c.term_id ),
			label: getDecodedString( c.name ),
			amount: c.count,
			icon_url: c.acf?.ikona?.url,
			thumbnail: c.acf?.obraz?.url,
			subitems: subcategories
				.filter( s => s.parent === c.term_id )
				.map( s => ({
					value: String( s.term_id ),
					label: s.name,
					amount: s.count
				}))
		}))
	})
}