import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import FavoritesContextProvider from "context/FavoritesContext";
import NotificationsContextProvider from "context/NotificationsContext";
import API from "api/API";
import AuthAPI from "api/AuthAPI";
import Paths from "routes/Paths";
import Storage from "extra/Storage";
import { getAllImgSizes } from "propsFormat/general";

import Loader from "components/general/Loader";
import Header from "components/main/Header";
import Footer from "components/main/Footer";
import Routes from "routes/Routes";

const AuthContext = React.createContext();

const PathsNeedAuth = [ 
	Paths.Profile.SavedSearch, Paths.Profile.Settings, Paths.Profile.CreateOwnTrip, 
	Paths.Profile.Favorites, Paths.Profile.Notifications, Paths.Profile.NotificationSettings
];

export default function AuthContextProvider() {

	const navigate = useNavigate();
	const location = useLocation();

	const [ facebook_sdk_loaded, setFacebookSdkLoaded ] = useState( false );
	const [ loading, setLoading ] = useState( true );
	const [ user, setUser ] = useState( null );


	const getUserData = () => {
		return AuthAPI.me()
			.then(({ data }) => {

				const images = getAllImgSizes( data.acf?.profile_image );
				const avatar = images.img_thumbnail || images.img_medium || images.img; 

				setUser({
					id: data.id,
					email : data.email,
					username: data.username,
					url: data.url,
					avatar: avatar,
					country: data.acf?.country,
					settings: {
						notification_favorite: data.acf?.notification_favorite,
						notification_search_parameters: data.acf?.notification_search_parameters,
						notification_email: data.acf?.notification_email
					}
				})
			})
			.catch(() => logOut())
			.finally(() => setLoading( false ));
	}


	const logIn = data => {
		
		const remember_me = data.checkbox;

		return AuthAPI.login( data )
			.then( res => {
		
				const { token } = res.data;
				if ( !token ) return Promise.reject( "wrong_credentials" );
						
				Storage.set( "token", token, !remember_me );
				return getUserData().then(() => Promise.resolve( "success_log_in" ));				
			});
	}


	const logOut = () => {
		setUser( null );
		Storage.remove( "token" );
		PathsNeedAuth.includes( location.pathname ) && navigate("/")
	}


	const facebookLogin = async () => {

		const { authResponse } = await new Promise( window.FB.login );
    	if ( !authResponse ) return;
		
		console.log( authResponse );
	}


	const googleCallback = data => {

		if ( !data.credential ) return;
		
		AuthAPI.googleSignIn( data.credential )
		.then( res => {
			
			const { token } = res.data;
			if ( token ) {
				Storage.set( "token", token );
				getUserData();
			}
		})
	}

	
	useEffect(() => {
		
		API.interceptors.response.use( res => {
			if ( res?.data?.code === 401 ) logOut();
			return res;
		}, err => {
			if ( err.response?.status === 401 ) logOut();
			return Promise.reject( err );
		});

	}, []);


	useEffect(() => {

		if ( window?.google?.accounts?.id ) {
			window.google.accounts.id.initialize({
				client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
				callback: googleCallback
			});
		}

		window.fbAsyncInit = function () {
			window.FB.init({
                appId: process.env.REACT_APP_FACEBOOK_APP_ID,
                cookie: true,
                xfbml: true,
                version: "v15.0"
            });

			window.FB.getLoginStatus( status => {
				process.env.NODE_ENV === "development" && console.log( status )
				setFacebookSdkLoaded( true );
            });
		}
	}, [])


	useEffect(() => {

		const stored_token = Storage.get( "token" );
		stored_token 
			? AuthAPI.tokenValidate()
				.then(() => getUserData())
				.catch(() => logOut()) 
			: setLoading( false );

	}, [])


	return (
		<AuthContext.Provider value={{ 
			user,
			facebook_sdk_loaded,
			updateUser: obj => setUser( u => ({...u, ...obj })),
			facebookLogin,
			logIn,
			logOut 
		}}>
			<FavoritesContextProvider>
				<NotificationsContextProvider>
					<Header/>

						<main> 
							{ loading 
								? <Loader/> 
								: <Routes authorized={ !!user }/>
							} 
						</main>
						
					<Footer/>
				</NotificationsContextProvider>
			</FavoritesContextProvider>
		</AuthContext.Provider>
	)
}


export const useUser = with_update => {
	const { user, updateUser } = useContext( AuthContext ); 
	return with_update ? [ user, updateUser ] : user;
}


export const useFacebookAuth = () => {
	const { facebook_sdk_loaded, facebookLogin } = useContext( AuthContext );
	return { facebook_sdk_loaded, facebookLogin };
}

export const useLogIn = () => useContext( AuthContext ).logIn;

export const useLogOut = () => useContext( AuthContext ).logOut;
