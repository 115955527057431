import Options from "../Options";
import useContinents from "hooks/Continent/useContinents";

export default function ContinentOptions( props ) {
	
	const continents_options = useContinents();

	return (
		<Options
			label={ props.label || "choose_continent" }
			options={ continents_options }
			{...props }
		/>
	)
}