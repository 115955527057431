import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Paths from "routes/Paths";
import { useLogIn } from "context/AuthContext";
import { useTranslate } from "context/LanguageContext";
import useFormData from "hooks/useFormData";

import Form from "components/form/Form";
import Button from "components/buttons/Button";
import Checkbox from "components/form/Checkbox"

import inputs from "./inputs";
import AuthAPI from "api/AuthAPI";


export default function AuthForm({ mode, submit_label, changeMode, onClose }) {
	
	const navigate = useNavigate();
	const logIn = useLogIn();
	const translate = useTranslate();

	const { data, changeData, clearData } = useFormData();

	const getAPIMethod = () => {
		switch ( mode ) {
			case "login": return logIn;
			case "registration": return AuthAPI.registration;
			case "remind_password": return AuthAPI.remindPassword;
			case "validate_code": return AuthAPI.validRemindPasswordCode;
			case "set_password": return AuthAPI.setNewPassword;
			default: return null;
		}
	}

	const validationFn = () => {

		if ( mode === "registration" && !data.checkbox ) {
			return [ "regulations_required" ];
		}

		if ( mode === "set_password" && ( data.password !== data.repeat_password )) {
			return [ "passwords_not_same" ];
		}
	}


	const formSubmit = () => {

		const APIMethod = getAPIMethod();

		return APIMethod( data )
			.then( message => {

				const password = data.password;
				const email = data.email;
				const code = data.code;

				switch ( mode ) {
					case "login": 
						onClose();
						break;
					
					case "registration": 
						changeMode( "login" );
						break;

					case "remind_password": 
						changeMode( "validate_code" );
						setTimeout(() => changeData( "email", email ), 60 );
						break;

					case "validate_code":
						changeMode( "set_password" );
						setTimeout(() => changeData({ email, code }), 60 );
						break;

					case "set_password":
						changeMode( "login" );
						setTimeout(() => changeData({ email, password }), 60 );
						break;
				}

				return message;
			})
	}


	useEffect(() => { clearData()}, [ mode ])

	return (
		<Form 
			className="auth-form"
			validationFn={ validationFn }
			formSubmit={ formSubmit }
		>

			{ inputs.map(({ required_modes, Component, ...input }) => {

				return !required_modes || required_modes.includes( mode )
					? <Component 
						key={ input.name }
						{...input }
						value={ data[ input.name ] || "" }
						onChange={ changeData }	
					/>
					: null
			})}


			{[ "login", "registration" ].includes( mode ) && 
				<Checkbox 
					name="checkbox"
					checked={ data.checkbox }
					onClick={ changeData }	
				>
					{ mode === "login" && translate( "remember_me" )}

					{ mode === "registration" && 
						<> 
							{ translate( "registration_text_1" )}
							<a href="#regulations" onClick={ e => {
								e.preventDefault();
								e.stopPropagation();
								navigate( Paths.Regulations );
							}}> { translate( "regulations" )} </a> 
							{ translate( "registration_text_2" )}
						</> 
					}
				</Checkbox>
			}

			{ mode === "login" && <a href="#remind-password" onClick={ e => {
				e.preventDefault();
				changeMode( "remind_password" );
			}}> { translate( "remind_password" )} </a> }

			<Button 
				type="submit" 
				extra_classes="yellow"
			> { submit_label } </Button>

		</Form>
	)
}