import "./mobile-menu-button.scss";

export default function MobileMenuButton({ open, onClick }) {
		
	return (
		<button 
			type="button" 	
			className={`mobile-menu-button ${ open ? "open": ""}`}
			onClick={ onClick }
		>
			<span/>
			<span/>
		</button>
	)
}