import Checkbox from "components/form/Checkbox";

export default function DropdownOptions( props ) {

	const { chosen, options, setChosen } = props;
	
	return (
		<div className="dropdown-options has-scrollbar">
			{ options && !!options.length &&
				options.map(({ value, label, icon_url }) => (
					<div key={ value } className="dropdown-options-item">
						<Checkbox 
							checked={ chosen?.includes( value )}
							onClick={() => setChosen( value )}	
						>  
							{ icon_url && <img src={ icon_url} alt="ikona"/> }
							{ label }
						</Checkbox>
					</div>
				))
			}
		</div>
	)
}