import { useState } from "react";
import useDidChange from "hooks/useDidChange";

import { MinusSvg, PlusSvg } from "svg";
import "./counter.scss";

export default function Counter( props ) {
	
	const { label, default_value, onChange } = props;
	const [ amount, setAmount ] = useState( +default_value || 0 );

	const changeAmount = action => {
		switch ( action ) {
			case "plus": setAmount( amount + 1 ); return;
			case "minus": setAmount( amount === 0 ? 0 : amount - 1); return;

			default: return;
		}
	}


	useDidChange(() => { onChange && onChange( amount )}, [ amount ]);

	return (
		<div className="counter">
			<label> { label } </label>

			<button type="button" onClick={() => changeAmount( "minus" )}> 
				<MinusSvg/> 
			</button>
			
			<strong> { amount } </strong>
			
			<button type="button" onClick={() => changeAmount( "plus" )}> 
				<PlusSvg/> 
			</button>
		</div>
	)
}