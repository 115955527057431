import { Link } from "react-router-dom";
import Loader from "components/general/Loader";
import "./button.scss";

export default function Button({ children, loading, extra_classes = "", ...props }) {
	
	const Tag = props.href ? "a" : props.to ? Link : "button";
	const className = [ "button", extra_classes ].join( " " ); 

	return (
		<Tag
			className={ className } 
			disabled={ loading }
			{...props }
		>
			{ loading ? <Loader/> : children } 
		</Tag>
	)
}