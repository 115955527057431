import { useNavigate } from "react-router-dom";
import { getURLSearchFromObject } from "extra/functions";
import Paths from "routes/Paths";

export default function() {
	const navigate = useNavigate();

	return params => navigate({
		pathname: Paths.Search,
		search: params ? getURLSearchFromObject( params ) : ""
	})
}