import { Link } from "react-router-dom";
import Paths from "routes/Paths";

import Thumbnail from "components/general/Thumbnail";
import FavoriteButton from "components/buttons/FavoriteButton";

import OfferTitlePlace from "../parts/OfferTitlePlace";
import OfferPrice from "../parts/OfferPrice";

import "./favorite-offer.scss";

export default function FavoriteOffer( props ) {
		
	const { id, title, dates, img_thumbnail, img_medium } = props;

	return (
		<Link 
			to={ Paths.Offer( id )}
			className="favorite-offer"
		>
			<Thumbnail src={ img_thumbnail || img_medium }>
				<FavoriteButton offer={ props }/>
			</Thumbnail>

			<div className="favorite-offer-main">
				<OfferTitlePlace title={ title }/>
			</div>

			<OfferPrice {...dates?.[0]}/>
		</Link>
	)
}