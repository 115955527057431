import { useTranslate } from "context/LanguageContext";

import Button from "components/buttons/Button";
import Loader from "components/general/Loader";
import Modal from "../Modal";
import "./confirm-modal.scss";

export default function ConfirmModal({ heading, message, loading, onClose, onConfirm }) {
	
	const translate = useTranslate();

	return (
		<Modal
			extra_classes="confirm-modal"
			heading={ translate( heading || "attention!" )}
			onClose={ onClose }
		>
			
			<div className="confirm-modal-message"> { translate( message || "" )} </div>

			{ loading 
				? <Loader/> 
				: (
					<div className="confirm-modal-buttons"> 
						<Button extra_classes="outline small" onClick={ onClose }> { translate( "no" )} </Button>
						<Button extra_classes="small" onClick={ onConfirm }> { translate( "yes" )} </Button>
					</div>
				)
			}
		</Modal>
	)
}