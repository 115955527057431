import useLocationDidChange from "hooks/useLocationDidChange";
import { useRef, useState, useEffect } from "react";
import useWindowResize from "hooks/useWindowResize";
import useWindowClick from "hooks/useWindowClick";

export default function withInnerPopup( Component ) {

	return function( props ) {
	
		const main_el_ref = useRef(); 
		const [ show_popup, setPopup ] = useState( false );

		const definePopupPosition = () => {

			if ( !main_el_ref.current ) return;
			const popup_el = main_el_ref.current.children?.[1];
			if ( !popup_el ) return;

			const main_rect = main_el_ref.current.getBoundingClientRect();
			const window_bottom = window.innerHeight + window.scrollY;
			const popup_right = main_rect.left + popup_el.offsetWidth;
			const popup_bottom = main_rect.bottom + 10 + popup_el.offsetHeight + window.scrollY;

			let top = "calc(100% + 10px)";

			if ( popup_bottom > window_bottom ) {
				top = -( popup_bottom - window_bottom - main_rect.height + 10 ); 

				if ( main_rect.top < -top ) {
					top = -main_rect.top + 10 + "px" 
				} else {
					top += "px"
				}
			}

			const left = popup_right > window.innerWidth ? -( popup_right - window.innerWidth + 10 ) + "px" : 0;  
			
			popup_el.style.left = left;
			// popup_el.style.top = top;
		}


		useWindowClick( e => {
			if ( !main_el_ref?.current ) return;
			!( main_el_ref.current.contains( e.target )) && setPopup( false );
		})


		useEffect(() => {
			if ( !show_popup ) return;
			definePopupPosition()
		}, [ show_popup ]);


		useWindowResize( definePopupPosition );

		useLocationDidChange(() => setPopup( false ))

		return (
			<Component 
				{...props } 
				show_popup={ show_popup } 
				setPopup={ setPopup } 
				ref={ main_el_ref } 
			/>
		)
	}
} 

