import { useTranslate } from "context/LanguageContext";
import "./form-input.scss";

export default function FormInput({ label, extra_classes = "", ...input_props }) {
	
	const translate = useTranslate();

	const id = input_props.id || input_props.name;
	const type = input_props.type || "text";

	const placeholder = translate( input_props.placeholder || "" );

	const onChange = e => input_props.onChange && input_props.onChange( input_props.name, e.target.value );

	return (
		<div className={`form-input ${ extra_classes }`}>
			
			{ label && 
				<label htmlFor={ id }> 
					{ translate( label )} { input_props.required && "*" } 
				</label> 
			}	
			
			{ type === "textarea" 
				? <textarea {...input_props } placeholder={ placeholder } onChange={ onChange }/>
				: <input {...input_props } placeholder={ placeholder } onChange={ onChange }/>
			}

		</div>
	)
}