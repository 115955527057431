import { useMemo } from "react";
import { useTranslate } from "context/LanguageContext";
import { DAY_NAMES_SHORT, MONTH_NAMES, getDaysAmountInMonth, getDayProperIndex, getCalendarDayData } from "extra/date";

import Arrows from "components/buttons/Arrows";
import "./calendar.scss";

export default function DateRangeCalendar( props ) {

	const translate = useTranslate();
	const { calendar_date, selected, range, show_arrows, changeDate, onDayClick } = props;

	const days = useMemo(() => {

		const { month, year } = calendar_date;
		if ( !month || !year ) return null;

		const days_amount = getDaysAmountInMonth( month, year );

		const days = [];

		let days_before = getDayProperIndex( new Date(`${ year }/${ month }/01`)) 
		let days_after = 6 - getDayProperIndex( new Date(`${ year }/${ month }/${ days_amount }`));
		
		for (; days_before > 0; days_before-- ) {
			days.push( null );
		}

		for ( let num = 1; num <= days_amount; num++ ) {
			
			const { date, date_iso, date_ms } = getCalendarDayData({ day: num, month, year });
			const extra_classes = [];

			if ( range ) {

				const range_from = range.from;
				const range_to = range.to;

				if ([ range_from?.date_iso, range_to?.date_iso ].includes( date_iso )) 
					extra_classes.push( "selected" );
				else if ( range_from?.date_ms && range_to?.date_ms ) 
					if ( date_ms > range_from.date_ms && date_ms < range_to.date_ms ) 
						extra_classes.push( "day-within-range" );
			} else if ( selected?.date_iso === date_iso ) {
				 extra_classes.push( "selected" )
			};

			days.push({ num, date, date_iso, date_ms, extra_classes: extra_classes.join(" ") });
		}

		for (; days_after > 0; days_after-- ) {
			days.push( null );
		}

		return days;

	}, [ calendar_date, range, selected ])


	return (
		<div className="calendar">

			<div className="calendar-head">

				{ show_arrows && <Arrows onClick={ changeDate }/> }

				<span> 
					{ calendar_date?.month ? translate( MONTH_NAMES[ calendar_date.month ]) : "" } { calendar_date?.year || "" } 
				</span>
			</div>
			
			<div className="calendar-day-names">
				{ DAY_NAMES_SHORT.map( name => ( <div key={ name } > { translate( name )} </div> ))}
			</div>

			<div className="calendar-days"> 
				{ days && !!days.length &&
					days.map(( day, i ) => day ? (
						<div 
							key={ day.date_iso }
							className={`calendar-day ${ day.extra_classes }`}
							onClick={() => onDayClick({ date: day.date, date_iso: day.date_iso, date_ms: day.date_ms })}
						> 
							<span> { day.num } </span> 
						</div>
					) : <div key={ i }/> )
				} 
			</div>
			
		</div>
	)
}