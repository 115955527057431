import { useTranslate } from "context/LanguageContext";
import { useState, useEffect } from "react";
import { EyeSvg } from "svg";

import "./form-password.scss";

export default function FormPassword({ label, extra_classes = "", ...input_props }) {
		
	const translate = useTranslate();
	const [ show_password, setShowPassword ] = useState( false );
	const id = input_props.id || input_props.name;
	const type = show_password ? "text" : "password";

	const onChange = e => input_props.onChange && input_props.onChange( input_props.name, e.target.value );

	useEffect(() => {

		const onMouseUp = () => setShowPassword( false );

		document.body.addEventListener( "touchend", onMouseUp );
		document.body.addEventListener( "mouseup", onMouseUp );

		return () => {
			document.body.removeEventListener( "touchend", onMouseUp );
			document.body.removeEventListener( "mouseup", onMouseUp );
		}
	}, [])


	return (
		<div className={`form-password ${ extra_classes }`}>
			
			{ label && <label htmlFor={ id }> { translate( label )} </label> }	
			
			<div className="form-password-main"> 
				<input 
					{...input_props }
					placeholder={ translate( input_props.placeholder || "" )}
					type={ type }
					onChange={ onChange }
				/>

				<div 
					onTouchStart={() => setShowPassword( true )} 
					onMouseDown={() => setShowPassword( true )}
				> 
					<EyeSvg/>
				</div>
			</div>
		</div>
	)
}