
export const getThumbnail = ( thumb_arr, size ) => {

	if ( !thumb_arr || !thumb_arr?.length ) return "";
	const full_size_src = thumb_arr[ thumb_arr.length - 1 ]?.src; 

	return !size 
		? full_size_src
		: thumb_arr.find( i => i.size === size )?.src || full_size_src;
}

export const getAllImgSizes = ( obj, di ) => Array.isArray( obj ) ? ({
	img: getThumbnail( obj ),		
	img_thumbnail: getThumbnail( obj, "thumbnail" ),
	img_medium: getThumbnail( obj, "medium" ),
	img_medium_large: getThumbnail( obj, "medium_large" ),
	img_large: getThumbnail( obj, "large" ),
	default_img: di
}) : ({
	img: obj?.url,		
	img_thumbnail: obj?.sizes?.thumbnail,
	img_medium: obj?.sizes?.medium,
	img_medium_large: obj?.sizes?.img_medium_large,
	img_large: obj?.sizes?.large,
	default_img: di
})