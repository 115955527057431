import Logo from "components/general/Logo"
import { useTranslate } from "context/LanguageContext"

export default function() {
	
	const translate = useTranslate();

	return (
		<div className="footer-copyright">
			<Logo/>
			<div> ©{ new Date().getFullYear()} Tripuj.pl. { translate( "all_rights_reserved" )} </div>
		</div>
	)
}