export default {

	"January": {
		"en": "January",
		"pl": "Styczeń",
	},

	"February": {
		"en": "February",
		"pl": "Luty",
	},

	"March": {
		"en": "March",
		"pl": "Marzec",
	},

	"April": {
		"en": "April",
		"pl": "Kwiecień",
	},

	"May": {
		"en": "May",
		"pl": "Maj",
	},

	"June": {
		"en": "June",
		"pl": "Czerwiec",
	},

	"July": {
		"en": "July",
		"pl": "Lipiec",
	},

	"August": {
		"en": "August",
		"pl": "Sierpień",
	},

	"September": {
		"en": "September",
		"pl": "Wrzesień",
	},

	"October": {
		"en": "October",
		"pl": "Październik",
	},

	"November": {
		"en": "November",
		"pl": "Listopad",
	},

	"December": {
		"en": "December",
		"pl": "Grudzień",
	},

	"mon": {
		"en": "Mn",
		"pl": "Pon"
	},

	"tue": {
		"en": "Tue",
		"pl": "Wt"
	},

	"wed": {
		"en": "Wd",
		"pl": "Śr"
	},

	"thu": {
		"en": "Th",
		"pl": "Cz"
	},

	"fri": {
		"en": "Fr",
		"pl": "Pt"
	},

	"sat": {
		"en": "St",
		"pl": "Sb"
	},

	"sun": {
		"en": "Sn",
		"pl": "Nd"
	}
}