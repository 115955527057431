
export default {
	
	set: ( key, value, session ) => {
		const storage = session ? sessionStorage : localStorage;
		storage.setItem( key, JSON.stringify( value ))
	},

	get: key => {
	
		const local_value = localStorage.getItem( key );
		const session_value = sessionStorage.getItem( key );
		const value = session_value || local_value;

		try {
			return JSON.parse( value );
		} catch {
			return value || null;
		}
	},

	remove: key => {
		localStorage.removeItem( key )
		sessionStorage.removeItem( key )
	}
}