import { CrossSvg } from "svg";

export default function DropdownChosen({ value, label, setChosen }) {
		
	return value && label ? (
		<div className="dropdown-chosen">
			<div className="dropdown-chosen-label"> 
				{ label }	 
			</div>

			<div 
				className="dropdown-chosen-close"
				onClick={ e => {
					e.stopPropagation();
					setChosen( c => c.filter( i => i !== value )) 
				}}
			>
				<CrossSvg/>
			</div>
		</div>
	) : null
}