import React, { useState, useEffect, useContext } from "react";
import { useUser } from "./AuthContext";
import UserAPI from "api/UserAPI";

const NotificationContext = React.createContext();

let timer;

export default function NotificationsContextProvider({ children }) {

	const user = useUser();
	const [ notifications, setNotifications ] = useState( null );


	const runTimer = () => {
		clearInterval( timer );
		timer = setInterval(() => getNotifications(), 1000 * 60 * 5 )
	}


	const getNotifications = () => {
		UserAPI.getNotifications()
		.then( res => {
			
			const notifications = ( res.data.posts || [] ).map( post => ({
				id: post.ID,
				offer_title: post.post_title,
				// text: "Offer '" + post.post_title + "' was updated!",
				time: post.post_modified
			}));
			
			setNotifications( notifications );
		})
	}


	useEffect(() => {
		
		clearInterval( timer );

		if ( user ) {
			getNotifications();
			runTimer();
		} else setNotifications( null );

		return () => clearInterval( timer );	
	}, [ user ])


	return (
		<NotificationContext.Provider value={{ notifications }}>
			{ children }
		</NotificationContext.Provider>
	)
}

export const useNotifications = () => {
	return useContext( NotificationContext ).notifications;
}