import Transport from "constants/Transport";
import CheckboxGroup from "components/form/CheckboxGroup";

import "./transport-checkboxes.scss";

export default function TransportCheckboxes( props ) {
	
	return (
		<div className="transport-checkboxes">
			<CheckboxGroup 
				name="transport" 
				{...props } 
				checkboxes={ Transport }
			/>
		</div>
	)
}