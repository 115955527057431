import Socials from "components/general/Socials";
import { useTranslate } from "context/LanguageContext";

export default function({ items }) {

	const translate = useTranslate();

	return (
		<div className="footer-socials"> 
			<Socials heading={ translate( "watch_us" )} items={ items }/>
		</div>
	)
}