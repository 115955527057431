import { useState } from "react";

import useCountries from "hooks/Country/useCountries";
import useContinents from "hooks/Continent/useContinents";
import { useTranslate } from "context/LanguageContext";

import Dropdown from "../Dropdown";
import WhereOptions from "./components/WhereOptions";

export default function WhereDropdown( props ) {
	
	const translate = useTranslate();
	const continents_options = useContinents();

	const [ chosen_continents, setChosenContinents ] = useState( props.default_continent || []);
	const [ continent, setContinent ] = useState( props.default_continent || []);
	const countries = useCountries( chosen_continents, props.offer_count );

	return continents_options && countries ? (
		<Dropdown
			update_chosen_on_options_change
			label={ translate( "where" )}
			search_placeholder={ translate( "from_country_or_city" )}
			options={ countries }
			OptionsComponent={ WhereOptions }
			options_component_props={{ 
				continent,
				chosen_continents,
				continents_options: continents_options,
				onChosenContinentsChange: ( name, value ) => {
					setChosenContinents( value );
				},
				onContinentChange: ( name, value ) => { 
					setContinent( value );
					props.onChange( name, value );
				} 
			}}
			{...props }
		/>
	) : <div className={`dropdown ${ props.extra_classes }`}> <div className="dropdown-main"/> </div>
}