export default {
	
	"start_login": {
		heading: "Aloha!", 
		text: "log_in_your_profile",
		bottom: {
			text: "dont_have_profile_yet",
			button_label: "create_account",
			mode_switch: "start_registration"
		}
	},

	"start_registration": {
		heading: "registration", 
		text: "sing_up_monitor_prices",
		bottom: {
			text: "already_have_profile",
			button_label: "log_in",
			mode_switch: "start_login"
		}
	},


	"login": {
		heading: "log_in_via_email", 
		text: "enter_ur_email_and_password",
		back_mode: "start_login",
		submit_label: "log_in",
		bottom: {
			text: "dont_have_profile_yet",
			button_label: "create_account",
			mode_switch: "start_registration"
		}
	},

	"registration": {
		heading: "registration_by_email", 
		text: "create_account_facilitate_use",
		back_mode: "start_registration",
		submit_label: "create_now",
		bottom: {
			text: "already_have_profile",
			button_label: "log_in",
			mode_switch: "start_login"
		}
	},

	"remind_password": {
		heading: "remind_password", 
		text: "enter_email_receive_password_reset",
		back_mode: "login",
		submit_label: "send_2",
		bottom: {
			text: "do_u_know_password",
			button_label: "log_in",
			mode_switch: "login"
		}
	},

	"validate_code": {
		heading: "code_validation",
		text: "please_enter_code",
		back_mode: "remind_password",
		submit_label: "send_2"
	},

	"set_password": {
		heading: "set_new_password",
		text: "",
		back_mode: "validate_code",
		submit_label: "send_2"
	}
}