import withModal from "hoc/withModal";

import ConfirmModal from "components/modal/ConfirmModal";
import { CrossSvg } from "svg";
import "./close-button.scss";

function CloseButton({ with_confirm, onClick, openModal }) {
		
	return (
		<button 
			type="button" 
			className="close-button"
			onClick={ e => {
				e.stopPropagation();
				e.preventDefault();

				!with_confirm && onClick();

				with_confirm && openModal({ 
					message: "are_u_sure_to_delte",
					onConfirm: () => {
						openModal( null );
						onClick();
					}
				})
			}}	
		>
			<CrossSvg/>
		</button>
	)
}

export default withModal( CloseButton, ConfirmModal ) 
