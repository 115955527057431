
import FormInput from "components/form/FormInput";
import FormPassword from "components/form/FormPassword";

export default [
	{
		label: "What's your name?",
		name: "username",
		autoComplete: "off",
		required_modes: [ "registration" ],
		Component: FormInput,
	},
	{
		label: "E-mail",
		name: "email",
		required_modes: [ "login" ],
		Component: FormInput,
	},
	{
		label: "email_address",
		name: "email",
		type: "email",
		required_modes: [ "registration", "remind_password" ],
		Component: FormInput,
	},
	{
		label: "password",
		name: "password",
		autoComplete: "new-password",
		Component: FormPassword,
		required_modes: [ "login", "registration" ]
	},
	{
		label: "code",
		name: "code",
		Component: FormInput,
		required_modes: [ "validate_code" ]
	},
	{
		label: "new_password",
		name: "password",
		autoComplete: "new-password",
		Component: FormPassword,
		required_modes: [ "set_password" ]
	},
	{
		label: "repeat_new_password",
		name: "repeat_password",
		autoComplete: "new-password",
		Component: FormPassword,
		required_modes: [ "set_password" ]
	}
]