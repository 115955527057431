import { useState } from "react";
import useLocationDidChange from "hooks/useLocationDidChange";
import { useTranslate } from "context/LanguageContext";

import { AngleRightSvg } from "svg";
import ProfileMenu from "components/navigation/ProfileMenu";

import "./styles/profile-layout.scss";
import "./styles/profile-layout-responsive.scss";

export default function ProfileLayout({ children, title }) {
	
	const translate = useTranslate();
	const [ menu_open, setMenuOpen ] = useState( false );

	useLocationDidChange(() => setMenuOpen( false ));

	return (
		<div className="profile-layout">

			<div className="container"> 
			
				<h1> { translate( title )} </h1>
			
				<div className="profile-layout-main"> 

					<div className={`profile-layout-sidebar ${ menu_open  ? "open" : "" }`}> 

						<button onClick={() => setMenuOpen( m => !m )}>
							<AngleRightSvg/>
						</button>

						<ProfileMenu/>
						
					</div>

					<div style={{ flex: "auto" }}> 
						{ children } 
					</div>
					
				</div>
			</div>

		</div>
	)
}