import axios from "axios";
import API from "./API";
import Storage from "extra/Storage";

const TOKEN_API = axios.create({ baseURL: window.config.ORIGIN + "/api/index.php/wp-json/jwt-auth/v1" })

const AUTH_API = axios.create({ baseURL: window.config.ORIGIN + "/api/index.php/wp-json/wp/v2" })

export default {
	
	login: data => AUTH_API.post( "/users/login", { email: data?.email, password: data?.password }),

	me: () => API.get( "/users/me" ),

	googleSignIn: credential => AUTH_API.post( "/users/google", { credential }),

	tokenValidate: () => TOKEN_API.post( "/token/validate", null, {
		headers: { Authorization: `Bearer ${ Storage.get( "token" )}`}
	}),
	
	registration: data => AUTH_API.post( "/users/register", {
		username: data.username,
		email: data.email,
		password: data.password
	}).then(() => Promise.resolve( "success_registration" )),

	remindPassword: data => API.post( "/reset-password", { 
		email: data.email 
	}).then(() => Promise.resolve( "password_reset_code_sent_to_u" )),

	validRemindPasswordCode: ({ email, code }) => API.post( "/validate-code", { email, code }),

	setNewPassword: data => API.post( "/set-password", { 
		email: data.email, 
		code: data.code, 
		password: data.password
	}).then(() => Promise.resolve( "password_successfully_changed" ))
}