import { useState } from "react";
import useDidChange from "hooks/useDidChange";
import { useTranslate } from "context/LanguageContext";
import { toggleArrValue } from "extra/functions";

import Checkbox from "components/form/Checkbox";

export default function CheckboxGroup({ name, checkboxes, default_value = [], onChange }) {
	
	const translate = useTranslate();
	const [ values, setValues ] = useState( default_value );

	useDidChange(() => { onChange && onChange( name, values )}, [ values ]);

	return checkboxes && !!checkboxes.length ? (
		<>
			{ checkboxes.map(({ label, value, svg = "" }) => (
				<Checkbox 
					key={ value } 
					checked={ values.includes( value )}
					onClick={() => setValues( toggleArrValue( value, values ))}
				> 
					{ svg }
					<span> { translate( label )} </span> 
				</Checkbox>
			))}
		</>
	) : null
}