import ContinentAPI from "api/ContinentAPI";
import useFetchData from "hooks/useFetchData";
import ContinentsSVG from "constants/ContinentsSVG";

export default function() {
	return useFetchData({
		APIMethod: ContinentAPI,
		cache_name: "continents",
		only_data: true,
		responseDataItemHandler: continent => ({
			
			label: {
				pl: continent.post_title,
				en: continent.acf?.en_tytul
			},

			value: String( continent.ID ),
			mark: continent.acf.short_code,
			svg: ContinentsSVG[ continent.post_title ]
		})
	})
}