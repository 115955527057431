import { useUser } from "context/AuthContext";
import withAuthModal from "hoc/withAuthModal";

import Widget from "../Widget";
import ProfileMenu from "components/navigation/ProfileMenu";
import { LoginSvg } from "svg";

import "./user-widget.scss";

function UserWidget({ openModal }) {
	
	const user = useUser();

	return (
		<Widget 
			className="user-widget"
			img={ user ? user?.avatar || require( "img/user-default.png" ) : null }
			svg={ !user ? <LoginSvg/> : null } 
			PopupComponent={ user ? () => <ProfileMenu widget/> : null }
			onClick={ !user ? () => openModal() : null }	
		/>
	)
}


export default withAuthModal( UserWidget );