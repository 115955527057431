import { scrollSmoothTo } from "extra/functions";
import useLocationDidChange from "hooks/useLocationDidChange";
import LanguageContextProvider from "context/LanguageContext";
import AuthContextProvider from "context/AuthContext";

import "styles/fonts.scss";
import "styles/main.scss";

export default function App() {

	useLocationDidChange( scrollSmoothTo );

  	return (
		<LanguageContextProvider>
			<AuthContextProvider/>
		</LanguageContextProvider>
	);
}
