import useFooter from "hooks/General/useFooter";

import FooterMenu from "components/navigation/FooterMenu";
import FooterCopyright from "./components/FooterCopyright";
import FooterContact from "./components/FooterContact";
import FooterSocials from "./components/FooterSocials";

import "./styles/footer.scss";
import "./styles/footer-responsive.scss";

export default function Footer() {

	const footer = useFooter();

	return footer ? (
		<footer className="footer">
			<div className="container">
				<FooterMenu/>
				<FooterCopyright/>
				<FooterContact {...footer.contact_details }/>
				<FooterSocials items={ footer.socials }/>
			</div>
		</footer>
	) : <footer className="footer"/>
}