import Menu from "../Menu"; 
import Paths from "routes/Paths";

import "./footer-menu.scss";

export default function FooterMenu() {

	const links = [
		{ label: "who_we_are", path: Paths.AboutUs },
		{ label: "Portfolio", path: Paths.Portfolio },
		{ label: "privacy_policy", path: Paths.PrivacyPolicy },
		{ label: "directions", path: Paths.Directions },
		{ label: "FAQ", path: Paths.FAQ },
		{ label: "regulations_tripuj", path: Paths.Regulations },
	]

	return (
		<Menu className="footer-menu" links={ links }/>
	)
}