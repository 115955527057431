import { useState, forwardRef } from "react";
import useDidChange from "hooks/useDidChange";
import { useTranslate } from "context/LanguageContext";

import withInnerPopup from "hoc/withInnerPopup";
import Counter from "components/form/Counter";

import "./people-counter.scss";

const PeopleCounter = forwardRef(( props, ref ) => {
	
	const translate = useTranslate();
	const { show_popup, setPopup } = props;
	const { default_value = {}, readOnly, extra_classes = "", onChange } = props;
	const [ amounts, setAmounts ] = useState({
		kids: +default_value.kids || 0,
		adults: +default_value.adults || 0
	});

	const total_amount = amounts.kids + amounts.adults;

	useDidChange(() => { onChange && onChange( amounts )}, [ amounts ])

	return (
		<div ref={ ref } className={`people-counter ${ extra_classes }`}>
			<div className="people-counter-main" onClick={() => setPopup( !show_popup )}>
				
				<span> { translate( "number_of_people" )} </span>
				
				{ total_amount > 0 && 
					<strong> 
						{ total_amount }

						{ total_amount === 1 
							? " " + translate( "person" )
							: total_amount <= 4
								? " " + translate( "persons_2" )
								: " " + translate( "persons" )
						}
					</strong> 
				} 
			</div>

			{ show_popup && !readOnly &&
				<div className="people-counter-popup">
					{
						[
							{ label: translate( "adults" ), name: "adults" }, 
							{ label:translate( "kids" ), name: "kids" }
						].map(({ label, name }) => (
							<Counter 
								key={ name } 
								label={ label }
								default_value={ amounts[ name ]}
								onChange={ amount => setAmounts({...amounts, [ name ]: amount })}
							/>
						))
					}
				</div>
			}

		</div>
	)
});

export default withInnerPopup( PeopleCounter );