import { useTranslate } from "context/LanguageContext";
import useOfferPrice from "hooks/Offer/useOfferPrice";
import "./offer-price.scss";

export default function OfferPrice({ sale, kids = 0, price, empty_price, ...props }) {
	
	const translate = useTranslate();
	const adults = props.adults ? props.adults : kids === 0 ? 1 : 0;
	const { sale_price, total_price, people_amount_label } = useOfferPrice({ kids, adults, sale, price })

	return (
		<div className="offer-price">

			{ empty_price
				? <div> <strong> { translate( "ask" )} </strong> </div>
				: (
					<>
						<div> { people_amount_label } </div>

						{( sale && total_price > 0 ) &&
							<span> { total_price } zł </span>
						}

						<strong> { sale ? ( sale_price || total_price ) : total_price } zł </strong>
					</>
				)
			}

			
		</div>
	)
}