import { useState } from "react";
import Checkbox from "components/form/Checkbox";

import "./categories-options.scss";

export default function CategoriesOptions( props ) {
	
	const { chosen, options, setChosen } = props;
	const [ show_subcategories, setShowSubcategories ] = useState( null );	

	return (
		<div className="categories-options">
			{ options && !!options.length && 
				options.map(({ value, label, icon_url, subitems }) => (
					<div 
						key={ value } 
						className="categories-options-box"
					>

						<div 
							className="categories-options-category"
							onClick={() => setShowSubcategories( s => s === value ? null : value )}	
						> 
							<img src={ icon_url } alt="ikona"/> 
							{ label } 

							<div onClick={ e => e.stopPropagation()}> 
								<Checkbox 
									checked={ chosen?.includes( value )}
									onClick={() => {		
										setChosen( value )
									}}	
								/>
							</div>
						</div>

						<div className="categories-options-subcategories">
							{ show_subcategories === value && subitems && !!subitems.length &&
								subitems.map(({ value, label }) => (
									<Checkbox 
										key={ value } 
										checked={ chosen?.includes( value )}
										onClick={() => setChosen( value )}	
									> 
										{ label } 
									</Checkbox>
								))
							}
						</div>
					</div>
				))
			}
		</div>
	)
}