import { useFavorites } from "context/FavoritesContext";

import Widget from "../Widget";
import FavoritesPopup from "./components/FavoritesPopup";
import { HeartSvg } from "svg";

import "./favorites-widget.scss";

export default function FavoritesWidget() {
		
	const favorites = useFavorites();

	return (
		<Widget 
			notification={ !!favorites?.length }
			className="favorites-widget"
			svg={ <HeartSvg/> }
			PopupComponent={() => <FavoritesPopup favorites={ favorites }/> }
		/>
	)
}