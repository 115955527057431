import { forwardRef } from "react";
import withInnerPopup from "hoc/withInnerPopup";

import "./widget.scss";

const Widget = forwardRef(( props, ref ) => {
	
	const { show_popup, setPopup } = props;

	const { 
		className = "", 
		svg, 
		img,
		notification, 
		children,
		onClick,
		PopupComponent
	} = props;

	return (
		<div 
			ref={ ref } 
			className={`widget ${ className }`}
		>
			
			<button
				className={`widget-button ${ show_popup ? "active" : "" }`}
				style={{ border: img ? "3px solid #FFD600" : "" }}
				onClick={() => onClick ? onClick() : setPopup( !show_popup )}	
			>
				{ svg }
				{ img && <img src={ img } alt="user"/> }
				{ notification && <span/> }
			</button>

			{ show_popup && 
				<div className="widget-popup">
					{ children }
					{ PopupComponent && <PopupComponent/> }
				</div>
			}
		</div>
	)
})

export default withInnerPopup( Widget );