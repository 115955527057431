import { addZero } from "./functions";

export const isDate = d => d?.toString() !== "Invalid Date" && toString.call( d ) === "[object Date]";

export const DAY_NAMES_SHORT = [ "mon", "tue", "wed", "thu", "fri", "sat", "sun" ];
 
export const MONTH_NAMES = [ "", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];

export const getDaysAmountInMonth = ( month, year ) => {

	if ( !year || !month ) return 0;

	const next_month = month + 1 !== 13 ? month + 1 : 1;
	const next_year = next_month === 1 ? year + 1 : year; 
		
	const next_month_date = new Date(`${ next_year }/${ next_month }/01 00:00:00`);
	return new Date( next_month_date - 60000 ).getDate();
}


export const getDateString = ( date, format = "DD.MM.YYYY" ) => {
	
	if ( !isDate( date ) && ( !date?.month || !date?.year )) return "";

	let day, month, year, hours = "", minutes = "";

	if ( isDate( date )) {
		day = date.getDate();
		month = date.getMonth() + 1;
		year = date.getFullYear(); 

		hours = date.getHours();
		minutes = date.getMinutes();
	} else {
		day = date.day || "01";
		month = date.month;
		year = date.year;

		hours = date.hours || "00";
		minutes = date.minutes || "00";
	}

	let result = format;
	result = result.replace( "DD", addZero( day ));
	result = result.replace( "MM", addZero( month ));
	result = result.replace( "YYYY", year );
	result = result.replace( "YY", String( year ).slice( 2 ));
	result = result.replace( "hh", addZero( hours ));
	result = result.replace( "mm", addZero( minutes ));


	return result;
}


export const getDayProperIndex = date => {
	if ( !isDate( date )) return null;
	return date.getDay() === 0 ? 6 : date.getDay() - 1;
}


export const getCalendarDayData = obj => {

	const date = getDateString( obj, "DD.MM.YY" );
	const date_iso = getDateString( obj, "YYYY/MM/DD" );
	const date_ms = +new Date( date_iso + " 00:00");

	return date ? { date, date_iso, date_ms } : null;
}