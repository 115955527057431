import { useState } from "react";
import { useTranslate } from "context/LanguageContext";
import useDidChange from "hooks/useDidChange";
import { toggleArrValue } from "extra/functions";

import Loader from "components/general/Loader";
import "./options.scss";

export default function Options( props ) {
	
	const translate = useTranslate();
	const { name, label, multiple, extra_classes = "", default_value, options, onChange } = props;
	const [ chosen, setChosen ] = useState( default_value || ( multiple ? [] : null ));

	useDidChange(() => { onChange && onChange( name, chosen )}, [ chosen ])

	return Array.isArray( options ) ? (
		<div className={`options ${ extra_classes }`}>
			<label> { translate( label )} </label>

			{ options && !!options.length &&
				<div className="options-row">
					{ options.map(({ value, label, mark, svg }) => { 
					
						const is_chosen = !multiple ? chosen === value : chosen.includes( value );

						return (
							<div 
								key={ value } 
								className={`options-item ${ is_chosen ? "chosen" : "" }`}
								onClick={() => setChosen(
									!multiple 
										? chosen === value ? null : value
										: toggleArrValue( value, chosen )
								)}
							>

								{ svg }
								{ mark && <span> { mark } </span> }
								{ translate( label )}
							</div> 
						)
					})}
				</div>
			}
		</div>
	) : <Loader/>
}