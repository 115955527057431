import Paths from "routes/Paths";
import Menu from "../Menu"; 
import "./main-menu.scss";

export default function MainMenu() {
	
	const links = [
		{ label: "sale", path: Paths.Sale },
		{ label: "insurance", path: Paths.Insurance },
		{ label: "Travel-Media", path: Paths.TravelMedia },
		{ label: "trips_for_companies", path: Paths.BusinessTrips },
		{ label: "who_we_are", path: Paths.AboutUs },
		{ label: "contact", path: Paths.Contact }
	]

	return (
		<Menu className="main-menu" links={ links }/>
	)
}