import { useEffect, useRef } from "react";

export default function useDidChange( cb, dependencies = []) {
	
	const did_mount = useRef( false );

	useEffect(() => {

		if ( !did_mount.current ) {
			did_mount.current = true;
			return;
		}

		cb();

	}, dependencies ); // eslint-disable-line
}