import { useEffect } from "react";
import { useTranslate } from "context/LanguageContext";

import BackButton from "components/buttons/BackButton";
import CloseButton from "components/buttons/CloseButton";
import "./modal.scss";

export default function Modal({ children, heading, extra_classes = "", onBack, onModalClick, onClose }) {
	
	const translate = useTranslate();

	useEffect(() => {
		document.body.style.overflow = "hidden";
		return () => document.body.style.overflow = "";
	}, []);


	useEffect(() => {

		const onEscapePress = e => e.code === "Escape" && onClose && onClose(); 

		document.body.addEventListener( "keydown", onEscapePress );
		return () => document.body.removeEventListener( "keydown", onEscapePress );
	}, [])


	return (
		<div 
			className={`modal ${ extra_classes }`} 
			onClick={ e => {
				e.stopPropagation()
				onModalClick && onModalClick(e);
			}}
		>
			<div className="modal-box">

				<div className="modal-box-head">
					{ onBack && <BackButton onClick={ onBack }/> }
					{ onClose && <CloseButton onClick={ onClose }/> }
				</div>
				
				<div className="modal-box-body has-scrollbar">
					{ heading && <h3> { translate( heading )} </h3> }
					
					{ children }
				</div>
			</div>
		</div>
	)
}