import calendar from "./calendar";
import individual from "./individual";

export default {
	...calendar,
	...individual,

	"loading...": {
		"en": "Loading...",
		"pl": "Ładowanie..."
	},

	"transport_mode": {
		"en": "Transport mode",
		"pl": "Środek transportu"
	},

	"category": {
		"en": "Category",
		"pl": "Kategoria"
	},

	"departure_category": {
		"en": "Departure category",
		"pl": "Kategoria wyjazdu"
	},

	"attributes": {
		"en": "Attributes",
		"pl": "Atrybuty"
	},

	"departure_attributes": {
		"en": "Departure attributes",
		"pl": "Atrybuty wyjazdu"
	},

	"when": {
		"en": "When",
		"pl": "Kiedy"
	},

	"select_travel_start_date_range": {
		"en": "Select a travel start date range",
		"pl": "Wybierz zakres dat rozpoczęcia wyjazdu"
	},

	"specify_length_of_trip": {
		"en": "Specify the length of the trip",
		"pl": "Określ długość wycieczki"
	},

	"any": {
		"en": "Any",
		"pl": "Dowolna"
	},

	"1_6_days": {
		"en": "1-6 days",
		"pl": "1-6 dni"
	},

	"7_10_days": {
		"en": "7-10 days",
		"pl": "7-10 dni"
	},

	"11_14_days": {
		"en": "11-14 days",
		"pl": "11-14 dni"
	},

	"more_than_14_days": {
		"en": "More than 14 days",
		"pl": "Więcej niż 14 dni"
	},

	"where": {
		"en": "Where",
		"pl": "Dokąd"
	},

	"from_country_or_city": {
		"en": "From country or city of departure",
		"pl": "Od kraju lub miasta wyjazdu"
	},

	"number_of_people": {
		"en": "Number of people",
		"pl": "Ilość osób"
	},

	"adults": {
		"en": "Adults",
		"pl": "Dorośli"
	},

	"kids": {
		"en": "Kids",
		"pl": "Dzieci"
	},

	"for": {
		"en": "For",
		"pl": "Za"
	},

	"person": {
		"en": "person",
		"pl": "osoba"
	},

	"persons": {
		"en": "persons",
		"pl": "osób"
	},

	"persons_2": {
		"en": "persons",
		"pl": "osoby"
	},

	"choose_continent": {
		"en": "Choose a continent",
		"pl": "Wybierz kontynent"
	},

	"choose_country": {
		"en": "Choose a country",
		"pl": "Wybierz kraj"
	},

	"search": {
		"en": "Search",
		"pl": "Szukaj"
	},

	"price": {
		"en": "Price",
		"pl": "Cena"
	},

	"sale": {
		"en": "Sale",
		"pl": "Promocje"
	},

	"show_promotions_only": {
		"en": "Show promotions only",
		"pl": "Pokaż tylko promocje"
	},

	"organizer": {
		"en": "Organizer",
		"pl": "Organizator"
	},

	"amenities": {
		"en": "Amenities",
		"pl": "Udogodnienia"
	},

	"dining": {
		"en": "Dining",
		"pl": "Wyżywienie"
	},
	
	"passport/visa": {
		"en": "Passport/visa",
		"pl": "Paszport/Wiza"
	},

	"no_passport/visa": {
		"en": "No passport/visa",
		"pl": "Bez paszportu/wizy"
	},

	"open_borders": {
		"en": "Open borders",
		"pl": "Granice otwarte"
	},

	"hotel_standard": {
		"en": "Hotel standard",
		"pl": "Standard hotelu"
	},

	"trip_rating": {
		"en": "Trip rating",
		"pl": "Ocena wycieczki"
	},

	"view_unrated_tours": {
		"en": "View unrated tours",
		"pl": "Wyświetlaj wycieczki bez ocen"
	},

	"use_option_to_save_search": {
		"en": "Use the option to save search parameters and check the offers in a while.",
		"pl": "Skorzystaj z opcji zapisu parametrów wyszukiwania i sprawdź oferty za jakiś czas."
	},

	"save": {
		"en": "Save",
		"pl": "Zapisz"
	},

	"most_popular": {
		"en": "Most popular",
		"pl": "Najpopularniejsze"
	},

	"closest": {
		"en": "The closest",
		"pl": "Najbliższe"
	},

	"from_lowest_price": {
		"en": "From the lowest price",
		"pl": "Od najniższej ceny"
	},

	"basic_params": {
		"en": "Basic parameters",
		"pl": "Parametry podstawowe"
	},

	"advanced_params": {
		"en": "Advanced parameters",
		"pl": "Parametry zaawansowane"
	},

	"send": {
		"en": "Send",
		"pl": "Wyślij"
	},

	"send_2": {
		"en": "Send",
		"pl": "Wysłać"
	},

	"address": {
		"en": "Address",
		"pl": "Adres"
	},

	"enter_email": {
		"en": "Enter e-mail",
		"pl": "Wpisz e-mail"
	},

	"email_address": {
		"en": "E-mail adress",
		"pl": "Adres e-mail"
	},

	"email_address_or_login": {
		"en": "Email address or login",
		"pl": "Adres e-mail lub login"
	},

	"what_your_name": {
		"en": "What's your name?",
		"pl": "Jak masz na imię?"
	},

	"password": {
		"en": "Password",
		"pl": "Hasło"
	},

	"repeat_email_address": {
		"en": "Repeat the email address",
		"pl": "Powtórz adres e-mail"
	},

	"phone_number": {
		"en": "Phone number",
		"pl": "Numer telefonu"
	},

	"question": {
		"en": "Question",
		"pl": "Pytanie"
	},

	"enter_question_content": {
		"en": "Enter question content",
		"pl": "Wpisz treść zapytania"
	},

	"comments/questions": {
		"en": "Comments/questions",
		"pl": "Uwagi/pytania"
	},

	"message_subject": {
		"en": "Message subject",
		"pl": "Temat wiadomości"
	},

	"message_content": {
		"en": "Message content",
		"pl": "Treść wiadomości"
	},

	"phone": {
		"en": "Phone",
		"pl": "Telefon"
	},

	"enter_phone": {
		"en": "Enter phone",
		"pl": "Wpisz telefon"
	},

	"describe_your_dream_trip": {
		"en": "Describe your dream trip",
		"pl": "Opisz swój wymarzony wyjazd"
	},

	"save_search_modal_heading": {
		"en": "Save the search parameters",
		"pl": "Zapisz parametry wyszukiwania"
	},

	"save_search_modal_text": {
		"en": "Give a name to the search parameters to make them easier to find in the list in your profile",
		"pl": "Nadaj nazwę dla wyszukiwanych parametrów, aby łatwiej znaleźć je na liście w swoim profilu"
	},

	"name": {
		"en": "Name",
		"pl": "Nazwa"
	},

	"page_not_found": {
		"en": "Page not found",
		"pl": "Strona nie została znaleziona"
	},

	"not_found_text": {
		"en": "Return to the main page or use the trip search engine below:",
		"pl": "Wróć na stronę główną lub skorzystaj z wyszukiwarki wyjazdów poniżej:"
	},

	"insurance": {
		"en": "Insurance",
		"pl": "Ubezpieczenie"
	},

	"trips_for_companies": {
		"en": "Trips for companies",
		"pl": "Wyjazdy dla firm"
	},

	"who_we_are": {
		"en": "Who we are",
		"pl": "Kim jesteśmy"
	},

	"contact": {
		"en": "Contact",
		"pl": "Kontakt"
	},

	"order_processing": {
		"en": "Order processing",
		"pl": "Obsługa zamówień"
	},

	"privacy_policy": {
		"en": "Privacy policy",
		"pl": "Polityka prywatności"
	},

	"directions": {
		"en": "Directions",
		"pl": "Kierunki"
	},

	"regulations_tripuj": {
		"en": "Terms and Conditions of TRIPJ.PL",
		"pl": "Regulamin TRIPUJ.PL"
	},

	"watch_us": {
		"en": "Watch us",
		"pl": "Obserwuj nas"
	},

	"contact_2": {
		"en": "Contact",
		"pl": "Skontaktuj się"
	},

	"all_rights_reserved": {
		"en": "All rights reserved",
		"pl": "Wszelkie prawa zastrzeżone"
	},

	"newsletter_heading_1": {
		"en": "Subscribe to the newsletter",
		"pl": "Zapisz się do newslettera"
	},

	"newsletter_heading_2": {
		"en": "Want to travel more often?",
		"pl": "Chcesz częściej podróżować?"
	},

	"subscribe": {
		"en": "Subscribe",
		"pl": "Zapisz się"
	},

	"saved_searches": {
		"en": "Saved searches",
		"pl": "Zapisane wyszukiwania"
	},

	"profile_settings": {
		"en": "Profile settings",
		"pl": "Ustawienia profilu"
	},

	"create_own_trip": {
		"en": "Create your own trip",
		"pl": "Stwórz własny wyjazd"
	},

	"favorite_trips": {
		"en": "Favorite trips",
		"pl": "Ulubione wyjazdy"
	},

	"notifications": {
		"en": "Notifications",
		"pl": "Powiadomienia"
	},

	"notification_settings": {
		"en": "Notification settings",
		"pl": "Ustawienia powiadomień"
	},

	"log_out": {
		"en": "Log out",
		"pl": "Wyloguj się"
	},

	"notification_setting_1": {
		"en": "Show notifications about your favorite offers",
		"pl": "Pokazuj powiadomienia o ulubionych ofertach"
	},

	"notification_setting_2": {
		"en": "Show notifications about new offers that match saved search parameters",
		"pl": "Pokazuj powiadomienia o nowych ofertach pasujących do zapisanych parametrów wyszukiwania"
	},

	"notification_setting_3": {
		"en": "Send notifications by e-mail",
		"pl": "Wysyłaj powiadomienia na e-mail"
	},

	"share_with_ur_friends": {
		"en": "Share with your friends:",
		"pl": "Udostępnij swoim przyjaciołom:"
	},

	"and": {
		"en": "and",
		"pl": "oraz"
	},

	"app_remind_1": {
		"en": "Friends, we remind you that we have a super convenient application that is available in",
		"pl": "Przyjaciele, przypominamy, że mamy super wygodną aplikację, która jest dostępna w "
	},

	"app_remind_2": {
		"en": "Download, try and leave your feedback!",
		"pl": "Pobierz, wypróbuj i zostaw swoją opinię!"
	},

	"search_date": {
		"en": "Search date",
		"pl": "Data wyszukiwania"
	},
	
	"no_name": {
		"en": "No name",
		"pl": "Bez nazwy"
	},

	"go_to_offers": {
		"en": "Go to offers",
		"pl": "Przejdź do ofert "
	},

	"name_2": {
		"en": "Name",
		"pl": "Imię"
	},

	"country": {
		"en": "Country",
		"pl": "Kraj"
	},
	
	"social_media": {
		"en": "Social media",
		"pl": "Media społecznościowe"
	},

	"photo": {
		"en": "Photo",
		"pl": "Zdjęcie"
	},

	"select_a_photo": {
		"en": "Select a photo",
		"pl": "Wybierz zdjęcie"
	},

	"change": {
		"en": "Change",
		"pl": "Zmień"
	},

	"current_password": {
		"en": "Current password",
		"pl": "Obecne hasło"
	},

	"new_password": {
		"en": "New password",
		"pl": "Nowe hasło"
	},

	"repeat_new_password": {
		"en": "Repeat new password",
		"pl": "Powtórz nowe hasło"
	},

	"change_password": {
		"en": "Change password",
		"pl": "Zmień hasło"
	},

	"cancel": {
		"en": "Cancel",
		"pl": "Anuluj"
	},

	"save_changes": {
		"en": "Save changes",
		"pl": "Zapisz zmiany"
	},

	"copy_link": {
		"en": "Copy link",
		"pl": "Skopiuj link"
	},

	"share": {
		"en": "Share",
		"pl": "Udostępnij"
	},

	"share_with_friends_and_family": {
		"en": "Share with friends and family:",
		"pl": "Podziel się z przyjaciółmi i rodziną:"
	},

	"share_on_facebook": {
		"en": "Share on Facebook",
		"pl": " Podziel się Facebooku"
	},

	"term": {
		"en": "Term",
		"pl": "Termin"
	},

	"summary": {
		"en": "Summary",
		"pl": "Podsumowanie"
	},

	"info_from_organizer": {
		"en": "Information from the organizer",
		"pl": "Informacje od organizatora"
	},

	"contact_with_organizer": {
		"en": "Contact with the organizer",
		"pl": "Kontakt z organizatorem"
	},

	"for_1_person": {
		"en": "For 1 person",
		"pl": "Za 1 osobę"
	},

	"book": {
		"en": "Book",
		"pl": "Rezerwuj"
	},

	"book_trip": {
		"en": "Book the trip",
		"pl": "Zarezerwuj wyjazd"
	},

	"ask_a_question": {
		"en": "Ask a question",
		"pl": "Zadaj pytanie "
	},

	"ask_about_offer": {
		"en": "Ask about the offer",
		"pl": "Zapytaj o ofertę"
	},

	"write_to_organizer": {
		"en": "Write to the organizer",
		"pl": "Napisz do organizatora"
	},

	"offer_form_text_1": {
		"en": "In the next step, fill in the information necessary to place an order. Have other questions?",
		"pl": "W następnym kroku wypełnisz informacje niezbędne do złożenia zamówienia. Masz inne pytania?"
	},

	"offer_form_text_2": {
		"en": "The reservation is inactive due to the exhaustion of the number of places for a given trip.",
		"pl": "Rezerwacja jest nieaktywna z uwagi na wyczerpanie ilość miejsc na dany wyjazd."
	},

	"offer_form_text_3": {
		"en": "with the organizer or search for another offer.",
		"pl": "z organizatorem lub wyszukaj inną ofertę."
	},

	"offer_organizer_description": {
		"en": "Offer organizer description",
		"pl": "Opis oferty organizatora"
	},

	"description": {
		"en": "Description",
		"pl": "Opis"
	},

	"appointment_number": {
		"en": "Appointment number",
		"pl": "Numer terminu"
	},

	"offer_details": {
		"en": "Offer details",
		"pl": "Szczegóły oferty"
	},

	"required": {
		"en": "required",
		"pl": "wymagane"
	},

	"not_required": {
		"en": "not required",
		"pl": "nie wymagane"
	},

	"restrictions_apply": {
		"en": "restrictions apply",
		"pl": "obowiązują ograniczenia"
	},

	"restrictions_not_apply": {
		"en": "restrictions don't apply",
		"pl": "ograniczenia nie obowiązują"
	},

	"about_organizer": {
		"en": "About the organizer",
		"pl": "O organizatorze"
	},

	"contact_details": {
		"en": "Contact details",
		"pl": "Dane kontaktowe "
	},

	"show_all": {
		"en": "Show all",
		"pl": "Pokaż wszystkie"
	},

	"collapse": {
		"en": "Collapse",
		"pl": "Zwiń"
	},

	"other_offers": {
		"en": "Other offers",
		"pl": "Inne oferty"
	},

	"trip_reviews": {
		"en": "Trip reviews",
		"pl": "Opinie o wycieczce"
	},

	"our_rating": {
		"en": "Our rating",
		"pl": "Nasza ocena"
	},

	"our_rating_1": {
		"en": "I advise against",
		"pl": "Odradzam"
	},

	"our_rating_2": {
		"en": "I do not recommend",
		"pl": "Nie polecam"
	},

	"our_rating_3": {
		"en": "Average quality",
		"pl": "Średnio"
	},

	"our_rating_4": {
		"en": "Quite good",
		"pl": "Całkiem dobrze"
	},

	"our_rating_5": {
		"en": "I recommend",
		"pl": "Super"
	},

	"leave_review": {
		"en": "Leave a review",
		"pl": "Zostaw opinię"
	},

	"leave_rating": {
		"en": "Leave a rating",
		"pl": "Zostaw ocenę"
	},
	
	"review": {
		"en": "Review",
		"pl": "Opinia"
	},

	"write_review": {
		"en": "Write a review...",
		"pl": "Napisz opinię..."
	},

	"send_feedback": {
		"en": "Send feedback",
		"pl": "Wyślij opinię"
	},

	"only_logged_can_write_review": {
		"en": "Only logged in users can write reviews!",
		"pl": "Opinie mogą wystawiać wyłącznie zalogowani użytkownicy!"
	},

	"log_in_to_rate_trips": {
		"en": "Log in to rate trips you have taken part in.",
		"pl": "Zaloguj się, aby oceniać wyjazdy, w których brałeś udział."
	},

	"registration": {
		"en": "Registration",
		"pl": "Rejestracja"
	},

	"sing_up_monitor_prices": {
		"en": "Sign up so we can monitor the prices of your favorite tickets and destinations and find great offers for you",
		"pl": "Zarejestruj się, abyśmy mogli monitorować ceny Twoich ulubionych biletów i miejsc docelowych oraz znaleźć dla Ciebie świetne oferty"
	},

	"log_in": {
		"en": "Log in",
		"pl": "Zaloguj się"
	},

	"log_in_your_profile": {
		"en": "Log in to your profile",
		"pl": "Zaloguj się do swojego profilu"
	},

	"dont_have_profile_yet": {
		"en": "Don't have a profile yet?",
		"pl": "Nie masz jeszcze profilu?"
	},

	"registration_by_email": {
		"en": "Registration via email",
		"pl": "Rejestracja przez e-mail"
	},

	"enter_ur_email_and_password": {
		"en": "Enter your e-mail address and password",
		"pl": "Podaj swój adres e-mail i hasło"
	},

	"via_facebook": {
		"en": "Via Facebook",
		"pl": "Przez Facebook"
	},

	"via_google": {
		"en": "Via Google",
		"pl": "Przez Google"
	},

	"via_email": {
		"en": "Via E-mail",
		"pl": "Przez E-mail"
	},

	"log_in_via_email": {
		"en": "Log in via email",
		"pl": "Zaloguj się przez e-mail"
	},

	"create_account": {
		"en": "Create an account",
		"pl": "Utwórz konto"
	},

	"create_account_facilitate_use": {
		"en": "Create an account to facilitate the use of tripuj.pl",
		"pl": "Utwórz konto, aby ułatwić korzystanie z tripuj.pl"
	},

	"already_have_profile": {
		"en": "Already have a profile?",
		"pl": "Masz już profil?"
	},

	"do_u_know_password": {
		"en": "Do you know the password?",
		"pl": "Znasz hasło?"
	},

	"enter_email_receive_password_reset": {
		"en": "Enter your e-mail address to which you will receive password reset instructions",
		"pl": "Podaj swój adres e-mail, na który otrzymasz instrukcję przypomnienia hasła"
	},

	"remember_me": {
		"en": "Remember me",
		"pl": "Zapamiętaj mnie"
	},

	"remind_password": {
		"en": "Remind password",
		"pl": "Przypomnij hasło"
	},

	"registration_text_1": {
		"en": "By registering in the system, I declare that I accept the",
		"pl": "Rejestrując się w systemie oświadczam, iż akceptuję"
	},

	"registration_text_2": {
		"en": "and consent to the processing of my data.",
		"pl": "oraz wyrażam zgodę na przetwarzanie moich danych."
	},

	"regulations": {
		"en": "Regulations",
		"pl": "Regulamin"
	},

	"create_now": {
		"en": "Create now",
		"pl": "Utwórz teraz"
	},

	"travel_media_#1": {
		"en": "Travel-media tripuj.pl - №1 in Poland",
		"pl": "Travel-media tripuj.pl - №1 w Polsce"
	},

	"no_articles_found": {
		"en": "No articles found",
		"pl": "Nie znaleziono artykułów"
	},

	"we_recommend": {
		"en": "We recommend",
		"pl": "Polecamy"
	},

	"start_date": {
		"en": "Start date",
		"pl": "Data rozpoczęcia"
	},

	"end_date": {
		"en": "End date",
		"pl": "Data zakończenia"
	},

	"select_a_date": {
		"en": "Select a date",
		"pl": "Wybierz datę"
	},

	"number_of_days": {
		"en": "Number of days",
		"pl": "Liczba dni"
	},

	"number_of_departing_persons": {
		"en": "Number of departing persons",
		"pl": "Liczba wyjeżdżających osób"
	},

	"are_all_departing_guests_in_poland": {
		"en": "Are all departing guests currently residing in Poland?",
		"pl": "Czy wszyscy wyjeżdżający przebywają aktualnie w Polsce?"
	},

	"date_birth_of_first_traveler": {
		"en": "Date of birth of the first traveler",
		"pl": "Data urodzenia pierwszego podróżującego"
	},

	"yes": {
		"en": "Yes",
		"pl": "Tak"
	},

	"no": {
		"en": "No",
		"pl": "Nie"
	},

	"what_continents_u_planning_visit": {
		"en": "What continents are you planning to visit?",
		"pl": "Jakie kontynenty planujesz odwiedzić?"
	},

	"what_countries_u_planning_visit": {
		"en": "What countries are you planning to visit?",
		"pl": "Jakie kraje planujesz odwiedzić?"
	},

	"what_purpose_of_trip": {
		"en": "What is the purpose of the trip?",
		"pl": "Jaki jest cel wyjazdu?"
	},

	"trip_purpose_1": {
		"en": "Leisure, sightseeing",
		"pl": "Wypoczynek, zwiedzanie"
	},

	"trip_purpose_2": {
		"en": "Skiing, snowboarding",
		"pl": "Narty, snowboard"
	},

	"trip_purpose_3": {
		"en": "Diving",
		"pl": "Nurkowanie"
	},

	"trip_purpose_4": {
		"en": "High risk sports",
		"pl": "Sporty wysokiego ryzyka"
	},

	"trip_purpose_5": {
		"en": "Extreme sports",
		"pl": "Sporty ekstremalne"
	},
	
	"trip_purpose_6": {
		"en": "Physical work",
		"pl": "Praca fizyczna"
	},

	"enter_u_email_save_comparison": {
		"en": "Enter your e-mail address to save your comparison - optional section",
		"pl": "Podaj adres e-mail, aby zapisać Twoje porównanie - sekcja nie obowiązkowa"
	},

	"insurance_email_agree": {
		"en": "I agree to receive commercial information by electronic means from the companies of the Rankomat Group, which includes Rankomat.pl sp. z o.o. (formerly: Rankomat sp. z o.o. sp.k.) and Ubezpieczenia Rankomat sp. z o.o. sp. k., to the telephone numbers and e-mail addresses provided.",
		"pl": "Wyrażam zgodę na otrzymywanie informacji handlowej drogą elektroniczną od spółek Grupy Rankomat, w skład której wchodzą Rankomat.pl sp. z o.o. (dawniej: Rankomat sp. z o.o. sp.k.) oraz Ubezpieczenia Rankomat sp. z o.o. sp. k., na podane numery telefonu i adresy e-mail."
	},

	"insurance_agree": {
		"en": "I declare that I have read and accept the regulations for the provision of services by Rankomat.pl sp. z o.o. (formerly: Rankomat sp. z o.o. sp.k.) and Ubezpieczenia Rankomat sp. z o.o. sp.k.",
		"pl": "Oświadczam, że zapoznałem się i akceptuję regulaminy świadczenia usług przez Rankomat.pl sp. z o.o. (dawniej: Rankomat sp. z o.o. sp.k.) oraz Ubezpieczenia Rankomat sp. z o.o. sp. k."
	},

	"insurance_text": {
		"en": "Read: Information about the administrator of your personal data, the purposes and rules of their processing and your rights, Information about the insurance distributor and Powers of Attorney to perform agency activities.",
		"pl": "Zapoznaj się z: Informacją o administratorze Twoich danych osobowych, celach i zasadach ich przetwarzania oraz należnych Ci prawach, Informacjami dotyczącymi dystrybutora ubezpieczeń oraz Pełnomocnictwami do wykonywania czynności agencyjnych."
	},

	"compare_offers": {
		"en": "Compare offers",
		"pl": "Porównaj oferty"
	},

	"complete_form_compare_offers": {
		"en": "Complete the form to compare offers",
		"pl": "Uzupełnij formularz, aby porównać oferty"
	},

	"NIP": {
		"en": "VAT",
		"pl": "NIP"
	},

	"regulations_required": {
		"en": "Regulation accept is required!",
		"pl": "Akceptacja regulaminu jest wymagana!"
	},

	"success_log_in": {
		"en": "You have successfully logged in!",
		"pl": "Logowanie zakończone powodzeniem!"
	},

	"success_registration": {
		"en": "You have successfully register!",
		"pl": "Pomyślnie się zarejestrowałeś!"
	},

	"wrong_credentials": {
		"en": "Wrong user credentials!",
		"pl": "Nieprawidłowe dane uwierzytelniające użytkownika!"
	},

	"password_reset_code_sent_to_u": {
		"en": "A password reset code has been sent to your email address!",
		"pl": "Kod resetowania hasła został wysłany na Twój adres e-mail"
	},

	"code": {
		"en": "Code",
		"pl": "Kod"
	},

	"code_validation": {
		"en": "Code validation",
		"pl": "Weryfikacja kodu"
	},

	"please_enter_code": {
		"en": "Please enter the code you get on email",
		"pl": "Wprowadź kod, który otrzymałeś w wiadomości e-mail"
	},

	"set_new_password": {
		"en": "Set new password",
		"pl": "Ustaw nowe hasło"
	},

	"passwords_not_same": {
		"en": "Passwords are not same!",
		"pl": "Hasła nie są takie same!"
	},

	"password_successfully_changed": {
		"en": "Password has been successfully changed!",
		"pl": "Hasło zostało pomyślnie zmienione!"
	},

	"empty_favorites": {
		"en": "You don't have any favorite trips",
		"pl": "Nie masz żadnych ulubionych wycieczek"
	},

	"all_offer_places": {
		"en": "All offer places",
		"pl": "Wszystkie oferują miejsca"
	},

	"all_offer_dates": {
		"en": "All offer dates",
		"pl": "Wszystkie terminy ofert"
	},

	"empty_notifications": {
		"en": "You have no notifications",
		"pl": "Nie masz żadnych powiadomień"
	},

	"see_all": {
		"en": "See all",
		"pl": "Zobacz wszystkie"
	},

	"empty_saved_searches": {
		"en": "You have no saved searches",
		"pl": "Nie masz żadnych zapisanych wyszukiwań"
	},

	"search_successfully_saved": {
		"en": "Your search parameters has been successfully saved!",
		"pl": "Twoje parametry wyszukiwania zostały pomyślnie zapisane!"
	},

	"please_enter_search_parameters_name": {
		"en": "Please enter a search parameters name!",
		"pl": "Wprowadź nazwę parametrów wyszukiwania!"
	},

	"ask_current_price": {
		"en": "Ask for the current price",
		"pl": "Zapytaj o aktualną cenę"
	},

	"ask_for_available_dates": {
		"en": "Ask for available dates",
		"pl": "Zapytaj o dostępne terminy"
	},

	"ask": {
		"en": "Ask",
		"pl": "Zapytaj"
	},

	"link_successfully_copied": {
		"en": "Link has been successfully copied!",
		"pl": "Link został pomyślnie skopiowany!"
	},

	"no_permission_copy_to_clipboard": {
		"en": "You have no permission for copying to clipboard!",
		"pl": "Nie masz uprawnień do kopiowania do schowka!"
	},

	"attention!": {
		"en": "Attention!",
		"pl": "Uwaga!"
	},

	"are_u_sure_to_delte": {
		"en": "Are you sure you want to delete?",
		"pl": "Jesteś pewien, że chcesz usunąć?"
	},

	"not_selected": {
		"en": "Not selected",
		"pl": "Nie zaznaczone"
	},

	"settings_success_changed": {
		"en": "Settings have been successfully changed!",
		"pl": "Ustawienia zostały pomyślnie zmienione!"
	},

	"subscribed_successfully": {
		"en": "You have been successfully subscribed to newsletter!",
		"pl": "Pomyślnie zapisałeś się do newslettera!"
	},

	"something_went_wrong": {
		"en": "Something went wrong!",
		"pl": "Coś poszło nie tak!"
	},

	"your_review_successfully_added": {
		"en": "Your review has been successfully added!",
		"pl": "Twoja recenzja została pomyślnie dodana!"
	},

	"you_sure_unsubscribe_newsletter": {
		"en": "Are you sure you want to unsubscribe newsletter?",
		"pl": "Czy na pewno chcesz zrezygnować z subskrypcji newslettera?"
	},

	"you_successfully_unsubscribed_from_newsletter": {
		"en": "You have been successfully unsubscribed from newsletter!",
		"pl": "Zostałeś pomyślnie wypisany z newslettera!"
	},

	"additional_info": {
		"en": "Additional information",
		"pl": "Dodatkowe informacje"
	},

	"offer_notification": title => ({
		"en": "Offer \"" + title + "\" was updated!",
		"pl": "Oferta \"" + title + "\" została zaktualizowana!"
	}),

	"data_successfully_sent": {
		"en": "Data has been successfully sent!",
		"pl": "Dane zostały pomyślnie wysłane!"
	},

	"personal_data_agree": {
		"en": "I agree to the processing of my personal data",
		"pl": "Wyrażam zgodę na przetwarzanie moich danych osobowych"
	},

	"agreement_required": {
		"en": "Agreement is required!",
		"pl": "Zgoda jest wymagana!"
	},

	"individual_term": {
		"en": "Individual term",
		"pl": "Termin indywidualny"
	},

	"open_date_explain": {
		"en": "Check the \"Individual term\" box to see the list of offers available on different dates, to be agreed with the organizer individually",
		"pl": "Zaznacz pole \"Termin indywidualny\" aby zobaczyć listę ofert dostępnych w różnych terminach, do ustalenia z organizatorem indywidualnie"
	},

	"show_results": {
		"en": "Show results",
		"pl": "Pokaż wyniki"
	}
}