import { useTranslate } from "context/LanguageContext";
import ContinentOptions from "components/form/ContinentOptions";
import Checkbox from "components/form/Checkbox";
import CheckboxGroup from "components/form/CheckboxGroup";

import "./styles/where-options.scss";
import "./styles/where-options-responsive.scss";

export default function WhereOptions( props ) {

	const translate = useTranslate();
	const { search_str, chosen, setChosen } = props;
	const { options, continent, chosen_continents, continents_options, onContinentChange, onChosenContinentsChange } = props;

	return (
		<div className="where-options">
			
			{ !search_str && 
				<ContinentOptions 
					multiple
					name="chosen_continents"
					options={ continents_options }
					default_value={ chosen_continents } 
					onChange={ onChosenContinentsChange }
				/>
			}

			{ !search_str && !!chosen_continents?.length && 
				<div style={{ margin: "16px 0" }}>
					<CheckboxGroup
						name="continent"
						default_value={ continent }
						checkboxes={ chosen_continents.map( id => ({
							value: id,
							label: continents_options.find( i => i.value === id )?.label
						}))}
						onChange={ onContinentChange }
					/>
				</div>
			}


			{ options && !!options.length &&
				<div className="where-options-countries has-scrollbar">  
					<div> { translate( "choose_country" )} </div>

					{ options.map(({ value, label, offer_count }) => (
						<div key={ value } className="where-options-country"> 
							<Checkbox 
								checked={ chosen?.includes( value )}
								onClick={() => setChosen( value )}	
							>  
								{ label } 
								
								{ offer_count > 0 && 
									<span> { offer_count } </span> 
								}
							</Checkbox>
						</div>
					)) }
				</div>
			}	
		</div>
	)
}