import PostsLoop from "components/general/PostsLoop";
import FavoriteOffer from "../FavoriteOffer";

export default function FavoriteOffersLoop( props ) {

	return (
		<PostsLoop 
			{...props }
			empty_text="empty_favorites"
			Component={ props.Component || FavoriteOffer }
		/>
	)
}