import { useEffect, useState, useMemo } from 'react';
import { getDebounceFn, isObject } from 'extra/functions';
import useCancelToken from './useCancelToken';
import useLocationDidChange from "./useLocationDidChange";

let cached = {}

export default function useFetchData({
	APIMethod,
	params = {},
	condition,
	only_data,
	with_update,
	data_param_name = "posts",
	cache_name,
	dependencies = [],
	delay = 500,
	filterHandler,
	responseDataHandler,
	responseDataItemHandler = item => item,
}) {

	const [ data, setData ] = useState( null );
	const [ pages_amount, setPagesAmount ] = useState(0);
	const [ getCancelToken, cancelQuery ] = useCancelToken();

	const getCachedData = () => {
		cached[ cache_name ] === "loading"
			? setTimeout(() => getCachedData(), 200 )
			: setData( filterData( cached[ cache_name ]))
	}


	const filterData = data => filterHandler ? filterHandler( data ) : data;

	const getData = params => {

		setData( null );
		cancelQuery();

		APIMethod( params, getCancelToken())
		.then( res => {

			const res_data = ( data_param_name ? res.data?.[ data_param_name ] : res.data ) || [];
			const pages_amount = res.data?.max_num_pages;

			const data = responseDataHandler 
				? responseDataHandler( res_data )
				: Array.isArray( res_data )
					? res_data.map( p => responseDataItemHandler( p ))
					: isObject( res_data )
						? responseDataItemHandler( res_data )
						: res_data;

			if ( cache_name ) cached[ cache_name ] = data;
					
			setData( filterData( data ));
			setPagesAmount( pages_amount );
		})
		.catch( err => { 
			// console.log( err );
			if ( cache_name ) cached[ cache_name ] = null 
		});
	}


	const debouncedGetData = useMemo(() => getDebounceFn( getData, delay ), [])

	useEffect(() => {

		if ( condition === false ) return;

		if ( cache_name && cached[ cache_name ]) getCachedData();
		else {
			if ( cache_name ) cached[ cache_name ] = "loading";
			debouncedGetData( params )
		}

	}, dependencies );

	
	useLocationDidChange(() => { !cache_name && setData( null )});
	
	return only_data ? data : with_update ? [ data, () => getData( params )] : [ data, pages_amount ];
}