import { useState } from "react";
import { useTranslate } from "context/LanguageContext";

import Modal from "components/modal/Modal";
import AuthStart from "./components/AuthStart";
import AuthForm from "./components/AuthForm";
import AuthBottom from "./components/AuthBottom";
import Modes from "./Modes";

import "./auth.scss";

export default function Auth({ default_mode, onClose }) {

	const translate = useTranslate();
	const [ mode, setMode ] = useState( default_mode || "start_login" );
	const { heading, text, bottom, back_mode, submit_label } = Modes[ mode ] || {};

	const MainComponent = [ "start_login", "start_registration" ].includes( mode ) ? AuthStart : AuthForm;

	const changeMode = m => setMode( m );

	return (
		<Modal extra_classes="auth" onBack={ back_mode ? () => changeMode( back_mode ) : null } onClose={ onClose }>
			<h2> { translate( heading )} </h2>
			<p> { translate( text )} </p>

			<MainComponent 
				mode={ mode } 
				submit_label={ translate( submit_label )} 
				changeMode={ changeMode }
				onClose={ onClose }
			/>

			<AuthBottom 
				{ ...bottom } 
				onClick={() => changeMode( bottom.mode_switch )}
			/>
		</Modal>
	)
}