import { useState, useEffect, useRef } from "react";
import useWindowResize from "hooks/useWindowResize";
import { Link } from "react-router-dom";

import "./thumbnail.scss";

export default function Thumbnail({ className = "", src, children, style = {}, height_ratio, ...rest }) {

	const thumb_ref = useRef();
	const thumb_height = useWindowResize(() => thumb_ref?.current?.offsetWidth * height_ratio || 0 ); 

	const [ img_loaded, setImgLoaded ] = useState( false );
	const Component = rest.to ? Link : "div"; 

	useEffect(() => {

		let img = new Image();
		img.src = src;
		img.onload = () => setImgLoaded( true );

		return () => img = null;
	}, [ src ]);


	return (
		<Component
			ref={ thumb_ref } 
			className={`thumbnail ${ className }`} 
			style={{ 
				backgroundImage: `url(${ src })`, 
				backgroundSize: img_loaded ? "cover" : "0",
				height: height_ratio ? thumb_height + "px" : "", 
				...style 
			}} 
			{...rest }
		> 
			{ children } 
		</Component>
	)
}