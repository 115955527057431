import { useNotifications } from "context/NotificationsContext";

import Widget from "../Widget";
import NotificationsPopup from "components/notification/NotificationsPopup";
import { NotificationSvg } from "svg";

import "./notification-widget.scss";

export default function NotificationsWidget() {
	
	const notifications = useNotifications();

	return (
		<Widget 
			notification={ !!notifications?.length }
			className="notification-widget"
			svg={ <NotificationSvg/> }
			PopupComponent={() => <NotificationsPopup notifications={ notifications }/> }
		/>
	)
}