import { useEffect } from "react";
import { useFacebookAuth } from "context/AuthContext";
import { useTranslate } from "context/LanguageContext";
import { EnvelopeSvg } from "svg";
import { FacebookSvg, GoogleSvg } from "svg/socials";

const buttons = [
	{ label: "via_facebook", value: "facebook", svg: <FacebookSvg/> },
	{ label: "via_google", value: "google", svg: <GoogleSvg/> },
	{ label: "via_email", value: "email", svg: <EnvelopeSvg/> },
]

export default function AuthStart({ mode, changeMode, onClose }) {
	
	const translate = useTranslate();
	const { facebook_sdk_loaded, facebookLogin } = useFacebookAuth();
	
	useEffect(() => {
		window.google.accounts.id.renderButton( document.querySelector("#google-btn"), { 
			size: "normal",
			width: 336,
			click_listener: () => onClose()
		});
	}, [])

	
	return (
		<div className="auth-start">
			{ buttons.map(({ svg, label, value }) => {

				if ( value === "facebook" && !facebook_sdk_loaded ) 
					return null;

				return (
					<button key={ value } data-value={ value } onClick={() => {

						switch ( value ) {
							case "facebook": 
								facebookLogin();
								onClose();
								return;
	
							case "email":
								changeMode( mode === "start_login" ? "login" : "registration" )
								return;
						} 
					}}>
						{ svg }
	
						<span> { translate( label )} </span>
						<strong/>
	
						{ value === "google" && <div id="google-btn"/> }
					</button>
				)
			})}
		</div>
	)
}