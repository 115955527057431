
const Toasts = ( function() {

	let toast_id = 1;
	let all_toasts = [];	

	return {
		updatePositions: () => {

			let top = 10;
			
			all_toasts.forEach( toast => {
				toast.style.top = top + "px";
				top += toast.offsetHeight + 5;
			});
		},
	
		add: toast => {
			all_toasts.push( toast );
			Toasts.updatePositions();
		},
	
		delete: toast => {

			clearTimeout( toast.auto_delete );
	
			all_toasts = all_toasts.filter( t => t.toast_id !== toast.toast_id );
			Toasts.updatePositions();
		
			toast.style.top = -toast.offsetHeight + "px";
			setTimeout(() => toast.remove(), 600 );
		},
	
		create: ( type, message ) => {
	
			const toast = document.createElement( "div" );
			toast.innerHTML = message;
			toast.classList.add( "toast", type );
			toast.style.opacity = 0;
			toast.toast_id = toast_id;
			toast_id++;
		
			const link = document.createElement( "a" );
			link.innerHTML = "&#10006;";
			toast.appendChild( link );

			toast.addEventListener( "click", function(e) {
				e.target.tagName === "A" && Toasts.delete( this );
			})
		
			document.body.appendChild( toast );
			toast.style.top = -toast.offsetHeight + "px";
			toast.style.opacity = 1;
			
			toast.auto_delete = setTimeout(() => {
				Toasts.delete( toast )
			}, 8200 )
		
			setTimeout(() => Toasts.add( toast ), 100 );
		}
	}
})();


export default {
	success: m => Toasts.create( "success", m ),
	error: m => Toasts.create( "error", m )
}