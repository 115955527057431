import { useState } from "react";
import Toast from "extra/Toast";
import { useTranslate } from "context/LanguageContext";
import { isString } from "extra/functions";

const loader = `<div class="loader" style="height: auto"><div class="loader-box" style="width: 20px; height: 20px"><svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="path-1-inside-1_701_10296" fill="white"><path d="M49 24.5C49 38.031 38.031 49 24.5 49C10.969 49 0 38.031 0 24.5C0 10.969 10.969 0 24.5 0C38.031 0 49 10.969 49 24.5ZM5.20135 24.5C5.20135 35.1584 13.8416 43.7987 24.5 43.7987C35.1584 43.7987 43.7987 35.1584 43.7987 24.5C43.7987 13.8416 35.1584 5.20135 24.5 5.20135C13.8416 5.20135 5.20135 13.8416 5.20135 24.5Z"></path></mask><path d="M49 24.5C49 38.031 38.031 49 24.5 49C10.969 49 0 38.031 0 24.5C0 10.969 10.969 0 24.5 0C38.031 0 49 10.969 49 24.5ZM5.20135 24.5C5.20135 35.1584 13.8416 43.7987 24.5 43.7987C35.1584 43.7987 43.7987 35.1584 43.7987 24.5C43.7987 13.8416 35.1584 5.20135 24.5 5.20135C13.8416 5.20135 5.20135 13.8416 5.20135 24.5Z" stroke="#00184E" stroke-width="10" mask="url(#path-1-inside-1_701_10296)"></path><mask id="path-2-inside-2_701_10296" fill="white"><path d="M46.3993 24.5C47.8356 24.5 49.0143 25.6677 48.8621 27.0959C48.3937 31.4922 46.7419 35.6974 44.0602 39.2529C41.3785 42.8084 37.7891 45.5523 33.6909 47.2108C32.3594 47.7496 30.9128 46.9372 30.5181 45.5562C30.1234 44.1752 30.933 42.7515 32.2484 42.1749C35.2649 40.8525 37.9069 38.7734 39.9076 36.1209C41.9082 33.4683 43.1812 30.3567 43.6237 27.0929C43.8167 25.6696 44.963 24.5 46.3993 24.5Z"></path></mask><path d="M46.3993 24.5C47.8356 24.5 49.0143 25.6677 48.8621 27.0959C48.3937 31.4922 46.7419 35.6974 44.0602 39.2529C41.3785 42.8084 37.7891 45.5523 33.6909 47.2108C32.3594 47.7496 30.9128 46.9372 30.5181 45.5562C30.1234 44.1752 30.933 42.7515 32.2484 42.1749C35.2649 40.8525 37.9069 38.7734 39.9076 36.1209C41.9082 33.4683 43.1812 30.3567 43.6237 27.0929C43.8167 25.6696 44.963 24.5 46.3993 24.5Z" stroke="#FFD600" stroke-width="10" mask="url(#path-2-inside-2_701_10296)"></path></svg></div></div>`;

export default function Form({ className, children, validationFn, formSubmit }) {

	const translate = useTranslate();
	const [ querying, setQuerying ] = useState( false );

	const onSubmit = e => {
		e.preventDefault();

		if ( !formSubmit || querying ) return;
		if ( validationFn ) {
			const errors = validationFn( e.target );
			if ( errors ) {
				
				Toast.error( Array.isArray( errors )
					? errors.map( m => translate( m )).join( "<br/> <br/>" )
					: translate( errors ) 
				);

				return;
			}
		}

		const submit_btn = e.target.querySelector( "button[type=submit]");
		const submit_btn_label = submit_btn.innerHTML;

		setQuerying( true );
		submit_btn.disabled = true;
		submit_btn.innerHTML = loader;
		
		formSubmit( e.target )
		.then( message => isString( message ) && message && Toast.success( translate( message )))
		.catch( err => {

			const message = typeof err === "string" 
				? err
				: err?.response?.data?.message;

			message && Toast.error( translate( message || "something_went_wrong" ));
		})
		.finally(() => {
			setQuerying( false );
			submit_btn.disabled = false;
			submit_btn.innerHTML = submit_btn_label;
		})
	}
	

	return (
		<form className={ className } onSubmit={ onSubmit }>
			{ children }
		</form>
	)
}