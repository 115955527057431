import useDidChange from 'hooks/useDidChange';
import { useState } from 'react';
import { AngleLeftSvg, AngleRightSvg } from 'svg';

import "./pagination.scss";

export default function Pagination( props ) {

	const { pages_amount, onChange } = props;
	const [ active_page, setActivePage ] = useState( props.page || 1 );

	const getMiddleItems = () => {

		let start = 
			active_page - 1 <= 1 
				? 2 
				: active_page + 1 >= pages_amount
					? pages_amount - 3 > 1
						? pages_amount - 3
						: 2
					: active_page - 1;

		let end = start + 2 >= pages_amount ? pages_amount - 1 : start + 2;


		const pagination_items = [];
		for ( let num = start ; num <= end; num++ ) {
			pagination_items.push( getOneItem( num ) );
		}

		const prev_empty_item = start > 2 ? <span key="prev_empty" > ... </span> : null;
		const next_empty_item = end + 1 < pages_amount ? <span key="next_empty"> ... </span> : null;

		return [ prev_empty_item, ...pagination_items, next_empty_item ];
	}


	const getOneItem = ( num, action ) => {
		
		const classes = num === active_page ? "active" : action || "";

		return (
			<button
				key={ action || num }
				type="button"
				onClick={ e => { 
					e.preventDefault(); 
					setActivePage( num ); 
				}}
				className={ classes }> 
					{ !action && num } 

					{ action === "prev" && <AngleLeftSvg/> }
					{ action === "next" && <AngleRightSvg /> } 
			</button>
		)
	}


	useDidChange(() => {
		active_page > pages_amount && setActivePage( pages_amount );
	}, [ pages_amount ])

	
	useDidChange(() => { onChange && onChange( active_page )}, [ active_page ])

	return pages_amount > 1 && (
		<div className="pagination">

			{ active_page > 1 && getOneItem( active_page - 1, "prev" )}
				
			{ getOneItem( 1 )}

			{ getMiddleItems()}
				
			{ getOneItem( pages_amount )}
				
			{ active_page < pages_amount && getOneItem( active_page + 1, "next" )}
		</div>
	)
} 